import { useState } from "react"

import { useIntl } from "gatsby-plugin-intl"

const useMelissa = (options) => {
  const [addressError, setAddressError] = useState(false)
  /* 
    https://developers.google.com/maps/documentation/address-validation/demo
  */
  const { formatMessage } = useIntl()
  const checkAddress = async (addressLine1, postCode, country) => {
    if (!process.env.GOOGLE_MAPS_API_KEY) {
      setAddressError(
        formatMessage({
          id: "forms::address::keyError",
          defaultMessage: "Key error. Please try again.",
        })
      )
      return { result: false }
    }
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.GOOGLE_MAPS_API_KEY}`
    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address: {
          regionCode: country,
          addressLines: [addressLine1, postCode],
        },
      }),
    })
    const response = await resp.json()

    const result = response?.result
    const addressComponents = result?.address?.addressComponents || []
    const missingComponentTypes =
      response?.result?.address?.missingComponentTypes || []

    if (!result) {
      setAddressError(
        formatMessage({
          id: "forms::address::networkError",
          defaultMessage: "Network error. Please try again.",
        })
      )
      return { result: false }
    }

    const getComponentData = (componentType) => {
      const component = addressComponents.find(
        (item) => item?.componentType === componentType
      )
      // if (component?.confirmationLevel === "CONFIRMED") {
      //   return component?.componentName?.text
      // }
      return component?.componentName?.text
    }

    const formattedAddress = result?.address?.formattedAddress
    const countryCode = result?.address?.postalAddress?.regionCode
    const countryName = getComponentData("country")
    const city =
      getComponentData("locality") ||
      getComponentData("administrative_area_level_3") ||
      getComponentData("administrative_area_level_2") ||
      ""
    const region = getComponentData("administrative_area_level_2") || ""
    const postalCode = getComponentData("postal_code") || ""
    const street =
      getComponentData("route") || getComponentData("neighborhood") || ""
    const streetNumber =
      getComponentData("street_number") || getComponentData("premise") || ""
    const latitude = result?.geocode?.location?.latitude
    const longitude = result?.geocode?.location?.longitude
    if (
      (!city || !formattedAddress || !countryName || !postalCode) &&
      missingComponentTypes?.length
    ) {
      setAddressError(
        `${formatMessage({
          id: "forms::address::addressComponentError",
          defaultMessage: "Missing information",
        })}: ${missingComponentTypes.toString()}`
      )
      return { result: false }
    }
    if (!countryName || !postalCode) {
      setAddressError(
        formatMessage({
          id: "forms::address::notValid",
          defaultMessage:
            "Address or post code is not valid. Please try again.",
        })
      )
      return { result: false }
    }
    return {
      result: true,
      city,
      region,
      postalCode,
      latitude,
      longitude,
      formattedAddress,
      country: countryCode,
      addressLine1: `${street || ""} ${streetNumber || ""} ${
        postalCode || ""
      } ${city || ""} ${country || ""}`,
      resultData: result,
    }
  }

  return { checkAddress, addressError, setAddressError }
}

export default useMelissa
