import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { API_PERMISSIONS, VISIBILITY_STATUS } from "@tmu/apollo/constants"
import {
  Button,
  CustomModal,
  Money,
  SaleDetail,
  Spinner,
  Table,
  TooltipSelectMenu,
} from "@tmu/components/common"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { HomePageContext } from "@tmu/src/context/homePageContext"
import { StyledPageActions } from "@tmu/src/global/page-addons/dashboard.styles"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import { useAuth, useOffers, useToast } from "@tmu/src/hooks"
import { formatDate } from "@tmu/src/utils/date"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useContext, useEffect, useRef, useState } from "react"
import {
  StyledSimpleTextContent,
  StyledUsedCardTitle,
} from "../../dashboardPages/Profile/index.styles"
import { StyledPageWrapper, StyledTable } from "./index.styles"

const OffersTable = ({ searchText }) => {
  const { formatMessage } = useIntl()
  const { apiPermissions } = useAuth()
  const [detailStatus, setDetailStatus] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])
  const [firstLoad, setFirstLoad] = useState(false)
  const { setGlobalSpinnerStatus } = useContext(HomePageContext)
  const { error } = useToast()
  const {
    merchantService,
    callMerchantOffers,
    deleteOffer,
    partialUpdateOffer,
  } = useOffers({
    fetchPolicy: "network-only",
  })
  const { isTablet, isWide } = getAllScreenTypes()
  const [menuStatus, setMenuStatus] = useState({})
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const selectMenuRef = useRef()
  var orderCallInterval = null

  const handleLoadMore = merchantService?.handleLoadMoreByMerhant

  useEffect(() => {
    callMerchantOffers({ variables: { search: searchText } }).then((r) => {
      setFirstLoad(true)
    })
  }, [])

  useEffect(() => {
    clearTimeout(orderCallInterval)
    orderCallInterval = setTimeout(
      () =>
        callMerchantOffers({ variables: { search: searchText } }).then((r) => {
          setFirstLoad(true)
        }),
      1000
    )
  }, [searchText])

  const updateOffer = (variables) => {
    setGlobalSpinnerStatus(true)
    partialUpdateOffer({
      variables: {
        input: {
          ...variables,
        },
      },
    })
      .then((result) => {
        if (result?.errors?.length) {
          result?.errors?.forEach((err) => error(err?.message))
          return
        }

        refetch({ search: searchText })
      })
      .catch((err) => {
        error(err?.message)
      })
      .finally(() => setGlobalSpinnerStatus(false))
  }

  const offerData = merchantService?.data
  const loading = merchantService?.loading
  const pageInfo = merchantService?.pageInfo
  const refetch = merchantService?.refetch

  const menuItemEdit = {
    value: "edit",
    label: formatMessage({
      id: "offerTable::rowMenu::edit",
      defaultMessage: "Edit",
    }),
    action: (row) => {
      navigate(`/dashboard/merchants/stores/${row?.original?.id}`)
    },
  }

  const menuItemDelete = {
    value: "delete",
    label: formatMessage({
      id: "offerTable::rowMenu::delete",
      defaultMessage: "Delete",
    }),
    action: (row) => {
      setDeleteModalOpen(true)
      setSelectedRow(row)
      setMenuStatus({})
    },
  }

  const menuItemArchive = {
    value: "archive",
    label: formatMessage({
      id: "offerTable::rowMenu::archive",
      defaultMessage: "Archive",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.ARCHIVED,
        id: row?.original?.id,
      })
    },
  }

  const menuItemUnlist = {
    value: "unlist",
    label: formatMessage({
      id: "offerTable::rowMenu::unlist",
      defaultMessage: "Unlist",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.UNLISTED,
        id: row?.original?.id,
      })
    },
  }

  const menuItemList = {
    value: "list",
    label: formatMessage({
      id: "offerTable::rowMenu::list",
      defaultMessage: "List",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.LISTED,
        id: row?.original?.id,
      })
    },
  }

  const getMenuOptions = (visibilityStatus) => {
    const options = [menuItemEdit]

    if (visibilityStatus !== VISIBILITY_STATUS.ARCHIVED) {
      options.push(menuItemArchive)
    }

    if (visibilityStatus === VISIBILITY_STATUS.LISTED) {
      options.push(menuItemUnlist)
    } else if (visibilityStatus === VISIBILITY_STATUS.UNLISTED) {
      options.push(menuItemList)
    }

    options.push(menuItemDelete)

    return options
  }

  const menuWidth = isWide ? 115 : 85
  const tooltipHeight = 70

  const offers = offerData?.edges

  const data = !offerData
    ? []
    : offers?.map(({ node }) => {
        return {
          id: node?.id,
          startDate: formatDate(node?.startDate),
          endDate: formatDate(node?.endDate),
          offerName: node?.name,
          pacDiscount: node?.maxPacDiscount,
          total: node?.total,
          status: formatMessage({
            id: `visibility::type::${node?.visibilityStatus?.toUpperCase()}`,
            defaultMessage: node?.visibilityStatus,
          }),
          offer: node,
        }
      })

  const openDonationDetail = (row) => {
    setSelectedRow(row)
    setDetailStatus(true)
  }

  const closeDonationDetail = () => {
    setSelectedRow(null)
    setDetailStatus(false)
  }

  const columns = [
    {
      Header: (props) => (
        <div className="header-text extra-padding">
          <FormattedMessage
            id="dashboard::tableHeader::offer"
            defaultMessage="Offer"
          />
        </div>
      ),
      Cell: (props) => {
        return (
          <div>
            {isTablet ? (
              props?.value
            ) : (
              <div>
                {props?.value}
                <p className="mobile-date">{props?.row?.original?.startDate}</p>
              </div>
            )}
          </div>
        )
      },
      accessor: "offerName",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text extra-padding">
          <FormattedMessage
            id="dashboard::tableHeader::start"
            defaultMessage="Start"
          />
        </div>
      ),
      Cell: ({ value, row }) => {
        return (
          <div>
            {isTablet ? (
              <p>{value}</p>
            ) : (
              <>
                <p>{row?.values?.offerName}</p>
                <p className="mobile-date">{value}</p>
              </>
            )}
          </div>
        )
      },
      accessor: "startDate",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text extra-padding">
          <FormattedMessage
            id="dashboard::tableHeader::finish"
            defaultMessage="Finish"
          />
        </div>
      ),
      Cell: ({ value, row }) => {
        return (
          <div>
            {isTablet ? (
              <p>{value}</p>
            ) : (
              <>
                <p>{row?.values?.offerName}</p>
                <p className="mobile-date">{value}</p>
              </>
            )}
          </div>
        )
      },
      accessor: "endDate",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::pacDiscount"
            defaultMessage="Pac Discount"
          />
        </div>
      ),
      Cell: ({ value }) => {
        return (
          <div className="align-right">
            <Money value={value} currency={false} />%
          </div>
        )
      },
      accessor: "pacDiscount",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::status"
            defaultMessage="Status"
          />
        </div>
      ),
      Cell: ({ value }) => {
        return <div className="align-right">{value}</div>
      },
      accessor: "status",
      disableSort: true,
    },
    {
      Header: (props) => <div className="header-text align-right"></div>,
      Cell: (props) => {
        const { row } = props
        const { index, original } = row
        const offer = original?.offer
        const isDefaultOffer = offer?.isDefaultOffer
        const visibilityStatus = offer?.visibilityStatus
        if (isDefaultOffer) {
          return null
        }
        return (
          // apiPermissions?.includes(API_PERMISSIONS.MERCHANT_UPDATE_PRODUCT) && (
          <div className="dot-menu">
            <TooltipSelectMenu
              ref={selectMenuRef}
              menuIsOpen={menuStatus[index]}
              row={props.row}
              menuWidth={menuWidth}
              tooltipHeight={tooltipHeight}
              options={getMenuOptions(visibilityStatus)}
              onMouseLeave={() => {
                const temp = {}
                temp[index] = 0
                setMenuStatus({ ...menuStatus, ...temp })
              }}
              onMouseOver={() => {
                if (menuStatus[index] === 1) {
                  return
                }
                const temp = {}
                temp[index] = 1
                Object.keys(menuStatus)?.forEach((ind) => {
                  if (ind != index) {
                    temp[ind] = 0
                  }
                })
                setMenuStatus({ ...menuStatus, ...temp })
              }}
            />
          </div>
          // )
        )
      },
      accessor: "edit",
      disableSort: true,
    },
  ]

  const hiddenColumns = isTablet ? [] : ["startDate", "endDate", "total"]

  const handleRowClick = (row, index) => {
    openDonationDetail(row)
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  const handleSort = (sortBy) => {
    setSortInfo(sortBy)

    const sortValue = getSortValue(sortBy)
  }

  const getSortValue = (sortBy) => {
    const sortData = sortBy?.at(0)

    const direction = sortData?.desc === true ? "-" : ""
    let sortField = null

    switch (sortData?.id) {
      case "date":
        sortField = "created"
        break
      case "amount":
        sortField = "amount"
        break
      case "pacs":
        sortField = "earnedPac"
        break

      default:
        sortField = null
        break
    }
    return sortField ? direction + sortField : null
  }

  const handleCancelDelete = () => {
    setDeleteModalOpen(false)
    setSelectedRow(null)
  }

  const handleConfirmDelete = () => {
    setGlobalSpinnerStatus(true)
    deleteOffer({ variables: { id: selectedRow?.original?.id } })
      .then((r) => {
        merchantService?.refetch({ search: searchText })
      })
      .finally(() => setGlobalSpinnerStatus(false))
  }

  return !firstLoad || loading ? (
    <Spinner condensed />
  ) : offers?.length ? (
    <StyledPageWrapper>
      <StyledTable>
        <Table
          columns={columns}
          data={data}
          hiddenColumns={hiddenColumns}
          headerLineSeperator={true}
          onRowClick={!isTablet ? handleRowClick : null}
          initialSort={sortInfo}
          handleSort={handleSort}
          options={{ sort: true }}
        />
      </StyledTable>
      <CustomModal
        isModalOpen={!isTablet && selectedRow && detailStatus}
        cancelButtonAction={closeDonationDetail}
        children={
          <SaleDetail
            order={selectedRow?.original?.sale}
            rowData={selectedRow}
          />
        }
        isMobile={!isTablet}
        isCloseIcon={true}
        style={{
          overflow: "hidden",
        }}
      />
      {pageInfo?.hasNextPage && (
        <StyledPageActions align="center" data-testid="view-more">
          <Button
            data-testid="button-view-more"
            className="button-view-more"
            label="View More"
            color="carrot"
            variant="text"
            onClick={() =>
              handleLoadMore({ variables: { search: searchText } })
            }>
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage id="ui::viewMore" defaultMessage="View More" />
          </Button>
        </StyledPageActions>
      )}
      {isDeleteModalOpen && (
        <CustomModal
          isModalOpen={isDeleteModalOpen}
          style={isTablet && { textAlign: "unset" }}
          children={
            <>
              <StyledSimpleTextContent className="delete-account-text"></StyledSimpleTextContent>
              <Spacer bottom={isWide ? 3.5 : 1.5} />
            </>
          }
          header={
            <StyledUsedCardTitle>
              <FormattedMessage
                id="dashboard::offer::confirmDelete"
                defaultMessage="Are you sure to delete?"
              />
            </StyledUsedCardTitle>
          }
          isCloseIcon={true}
          cancelButtonText={
            <FormattedMessage
              id="dashboard::campaigns::modalNo"
              defaultMessage="No"
            />
          }
          cancelButtonAction={() => {
            handleCancelDelete()
          }}
          confirmButtonText={
            <FormattedMessage
              id="dashboard::campaigns::modalYes"
              defaultMessage="Yes"
            />
          }
          confirmButtonAction={() => {
            handleConfirmDelete()
            handleCancelDelete()
          }}
          confirmButtonColor="carrot"
          modalActionsStyle={{ display: "flex" }}
          isMobile={!isTablet}
          isFull={false}
        />
      )}
    </StyledPageWrapper>
  ) : (
    <EmptyTable
      emptyMessage={
        <FormattedMessage
          id="dashboard::offers::empty"
          defaultMessage="It seems there are no offers yet"
        />
      }
    />
  )
}

export default OffersTable
