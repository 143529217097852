import { useMutation } from "@apollo/client"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import { GENERATE_MERCHANT_REPORT_MUTATION } from "@tmu/apollo/dashboard/mutations/merchant"
import {
  Button,
  DatePicker,
  Spinner,
  TextInput,
  VoucherChecker,
} from "@tmu/components/common"
import { SalesTable } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledReportOptions,
} from "@tmu/global/page-addons/dashboard.styles"
import { useAuth, useToast } from "@tmu/hooks"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import theme from "@tmu/src/global/theme"
import { useDefaultMerchant } from "@tmu/src/hooks"
import { getAllScreenTypes } from "@tmu/src/utils/mediaQueries"
import { isBrowser } from "@tmu/utils/auth"
import { formatDate } from "@tmu/utils/date"
import { endDate, startDate } from "@tmu/utils/validation"
import { Form, Formik } from "formik"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useEffect, useState } from "react"
import { isSafari } from "react-device-detect"
import * as Yup from "yup"
import {
  StyledForm,
  StyledGenerateButton,
  StyledSearchContainer,
  StyledStickyButton,
  StyledTitleWrapper,
} from "./index.styles"

export default function Sales() {
  const { formatMessage } = useIntl()
  const { apiPermissions } = useAuth()
  const { success, error } = useToast()
  const [searchId, setSearchId] = useState("")
  const { merchantClient } = useApolloApiClients()
  const [selectedDate, setSelectedDate] = useState(null)
  const { isTablet } = getAllScreenTypes()
  const { isMerchantOnline } = useDefaultMerchant()
  const [filters, setFilters] = useState({})

  const [generateMerchantReport, { loading: loadingMerchantReport }] =
    useMutation(GENERATE_MERCHANT_REPORT_MUTATION, {
      client: merchantClient,
    })

  const initialValues = {
    startDate: null,
    endDate: null,
  }

  const validationSchema = Yup.object().shape({
    startDate: startDate({ formatMessage }),
    endDate: endDate({ formatMessage }),
  })

  const handleSubmit = (values, setSubmitting) => {
    setSubmitting(true)
    generateMerchantReport({
      variables: {
        input: {
          ...values,
          search: searchId,
        },
      },
      client: merchantClient,
    })
      .then((res) => {
        const generateMerchantReport = res?.data?.generateMerchantReport

        if (
          generateMerchantReport?.merchantReport?.report &&
          !loadingMerchantReport
        ) {
          if (isBrowser) {
            window.open(
              res?.data?.generateMerchantReport?.merchantReport?.report,
              "_blank"
            )
          }

          success(
            formatMessage({
              id: "dashboard::donations::reportSuccess",
              defaultMessage:
                "Your request is sent successfully! Also a copy of the report send to your email address.",
            })
          )
        }

        if (generateMerchantReport?.errors?.length > 0) {
          error(
            formatMessage({
              id: "dashboard::donations::reportError",
              defaultMessage: "An error occurred while processing your request",
            })
          )
        }
      })
      .catch((err) => {
        err &&
          error(
            formatMessage({
              id: "dashboard::donations::reportError",
              defaultMessage: "An error occurred while processing your request",
            })
          )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleSearch = (value) => {
    value = value?.trim()
    if (value?.length) {
      if (refetchDonationSearchCalled) {
        setRefetchLoading(true)
        setDataNotFound(false)
        refetchDonationSearch({ donationId: value })
          ?.then((d) => {
            setRefetchLoading(false)
            if (d?.data?.limitedDonation) {
              handleResult(refetchDonationSearchCalled, d?.data)
            } else {
              setDataNotFound(true)
              handleSearchError(d?.errors?.at(0))
            }
          })
          .catch((err) => {
            setDataNotFound(true)
          })
      } else {
        callDonationSearch({
          variables: { donationId: value },
        })
      }
    }
  }

  const generateReportText = formatMessage({
    id: "dashboard::donation::generateReport",
    defaultMessage: "Create a Report",
  })

  return (
    <StyledPage data-testid="sales-page-content">
      <StyledTitleWrapper>
        <StyledPageTitle data-testid="sales-page-header" className="main-title">
          <FormattedMessage
            id="dashboard::nav::sales"
            defaultMessage="Sales"
            tagName="h1"
          />
        </StyledPageTitle>
        {!isMerchantOnline && (
          <VoucherChecker isVoucherText={true} apiType={{ merchant: true }} />
        )}
      </StyledTitleWrapper>
      <Spacer bottom={1.5} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        {({ values, setFieldValue, isSubmitting, setSubmitting }) => (
          <Form onSubmit={() => handleSubmit(values, setSubmitting)}>
            {apiPermissions?.includes(API_PERMISSIONS.MERCHANT_VIEW_REPORTS) &&
              isTablet && (
                <StyledReportOptions>
                  <StyledSearchContainer>
                    <StyledForm onSubmit={() => handleSearch(searchId)}>
                      <FontAwesomeIcon icon={faSearch} />
                      <TextInput
                        data-testid="sales-search-box"
                        className={`float-container full-width search-input ellipsis-text`}
                        id="searchId"
                        name="searchId"
                        value={searchId}
                        type="text"
                        label={formatMessage({
                          id: "dashboard::sales::offerName",
                          defaultMessage: "Offer Name",
                        })}
                        placeholder=""
                        onChange={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          setSearchId(event?.target?.value)
                        }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter" || event.code === "Enter") {
                            event.preventDefault()
                            event.stopPropagation()
                            const val = event?.target?.value
                            setSearchId(val)
                            handleSearch(val)
                          }
                        }}
                      />
                    </StyledForm>
                  </StyledSearchContainer>
                  <DatePicker
                    showRange
                    minDate={new Date("2020-01-01")}
                    dataTestId="sales-report-date-picker"
                    startDate={selectedDate?.[0]}
                    endDate={selectedDate?.[1]}
                    onDateChange={(date) => {
                      let [startDate, endDate] = date

                      if (
                        startDate &&
                        endDate &&
                        startDate.getTime() === endDate.getTime()
                      ) {
                        endDate = new Date(endDate)
                        endDate.setHours(23, 59, 0, 0)
                      }

                      setSelectedDate([startDate, endDate])
                      const formattedStartDate = formatDate(
                        startDate,
                        "yyyy-MM-dd"
                      )
                      const formattedEndDate = formatDate(endDate, "yyyy-MM-dd")
                      setFieldValue("startDate", formattedStartDate, true)
                      setFieldValue("endDate", formattedEndDate, true)

                      setFilters({
                        ...filters,
                        created_Gte: startDate,
                        created_Lte: endDate,
                      })
                    }}
                    placeholderText={formatMessage({
                      id: "dashboard::campaignForm::startEndDate",
                      defaultMessage: "Start - End Date",
                    })}
                  />
                  {isTablet && (
                    <StyledGenerateButton data-testid="sales-generate-report">
                      <Button
                        color={theme.colors.carrot}
                        label="Generate Report"
                        onClick={() => handleSubmit(values, setSubmitting)}
                        style={{
                          marginLeft: isSafari ? "2.1875rem" : "1rem auto",
                          backgroundColor: theme.colors.carrot,
                        }}>
                        {isSubmitting ? (
                          <Spinner condensed />
                        ) : (
                          generateReportText
                        )}
                      </Button>
                    </StyledGenerateButton>
                  )}
                </StyledReportOptions>
              )}
          </Form>
        )}
      </Formik>
      <Spacer top={2.5} />
      <StyledPageContent>
        <SalesTable searchText={searchId} filters={filters} />

        {apiPermissions?.includes(API_PERMISSIONS.MERCHANT_VIEW_REPORTS) &&
          !isTablet && (
            <StyledStickyButton className="sticky-bottom-bar">
              <Button
                data-testid="btn-generate-report"
                color="transparent"
                type="button"
                onClick={() => handleSubmit()}>
                {generateReportText}
              </Button>
            </StyledStickyButton>
          )}
      </StyledPageContent>
    </StyledPage>
  )
}
