import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons/faMapMarkerAlt"
import {
  StyledLocationWrapper,
  StyledAddressSection,
  StyledAddressLine,
} from "./index.styles"
import { CustomModal } from "@tmu/components/common"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const LocationViewer = ({ address, isOffer }) => {
  const { addressLine1, addressLine2, locationLink, virtualEventLink } = address
  const [isModalOpen, setModalOpen] = useState(false)
  const { formatMessage } = useIntl()
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  return (
    <StyledLocationWrapper>
      <FontAwesomeIcon icon={faMapMarkerAlt} />
      {virtualEventLink ? (
        <StyledAddressSection aria-label="link">
          <StyledAddressLine
            className="broadcast"
            onClick={() => setModalOpen(true)}>
            <FormattedMessage
              id="campaign::detail:broadcastLink"
              defaultMessage="Broadcast link"
            />
          </StyledAddressLine>
          {isModalOpen && (
            <CustomModal
              style={{
                width: 615,
                maxWidth: isDesktop ? "45%" : isTablet ? "60%" : "90%",
              }}
              isModalOpen={isModalOpen}
              handleModalClose={() => setModalOpen(false)}
              header={
                <p style={{ width: "100%", fontFamily: "Open Sans Bold" }}>
                  {formatMessage({
                    id: "campaign::detail:broadcastLink",
                    defaultMessage: "Broadcast link",
                  })}
                </p>
              }
              text={
                <p
                  style={{
                    fontSize: isWide ? "1.5rem" : "1rem",
                    lineHeight: "1.5",
                    textAlign: "center",
                  }}>
                  {isOffer
                    ? formatMessage({
                        id: "campaign::detail:broadcastLinkTextOffer",
                        defaultMessage:
                          "We will send you the link to broadcast by mail after the purchase.",
                      })
                    : formatMessage({
                        id: "campaign::detail:broadcastLinkText",
                        defaultMessage:
                          "We will send you the link to broadcast by mail after recieving the donation.",
                      })}
                </p>
              }
              confirmButtonText={formatMessage({
                id: "dashboard::common::ok",
                defaultMessage: "OK",
              })}
              confirmButtonAction={() => {
                setModalOpen(false)
              }}
              confirmButtonColor={isOffer ? "carrot" : "blue"}
            />
          )}
        </StyledAddressSection>
      ) : (
        <StyledAddressSection
          className="address"
          href={locationLink}
          target="_blank"
          aria-label="link">
          <StyledAddressLine>{addressLine1}</StyledAddressLine>
          <StyledAddressLine>{addressLine2}</StyledAddressLine>
          <StyledAddressLine>
            {!addressLine1 && !addressLine2
              ? formatMessage({
                  id: "location::event::eventLocation",
                  defaultMessage: "Event Location",
                })
              : null}
          </StyledAddressLine>
        </StyledAddressSection>
      )}
    </StyledLocationWrapper>
  )
}

LocationViewer.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  locationLink: PropTypes.string,
  virtualEventLink: PropTypes.string,
}

export default LocationViewer
