import React, { useContext, useEffect, useRef, useState } from "react"
import { useMutation } from "@apollo/client"
import { useIntl } from "gatsby-plugin-intl"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faUserAlt } from "@fortawesome/pro-duotone-svg-icons/faUserAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import { HomePageContext } from "@tmu/context/homePageContext"
import theme from "@tmu/global/theme"
import { CustomModal, Spinner } from "@tmu/components/common"
import {
  useAuth,
  // useShoppingCart,
  useOnClickOutside,
  useDefaultMerchant,
  useDefaultPartner,
} from "@tmu/hooks"
import { UPDATE_ME_MUTATION } from "@tmu/apollo/storefront/mutations/user"
import { ME_QUERY } from "@tmu/apollo/storefront/queries/user"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import queryString from "query-string"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import {
  StyledAvatar,
  StyledLeftGetStartedButton,
  StyledLeftMenuItem,
  StyledLeftMenuItemList,
  StyledLeftMenuTextWithIconWrapper,
  StyledLeftMenuUserInfo,
  StyledMerchantType,
  StyledMerchantsTypeWrapper,
  StyledLeftSigninButton,
  StyledMenuOverlay,
  StyledMerchant,
  StyledMerchantIcon,
  StyledMerchantOptions,
  StyledMerchantStatus,
  StyledMerchantTitle,
  StyledMerchantsWrapper,
  StyledMobileAuthSection,
  StyledMobileMenu,
  StyledMobileNavButton,
  StyledSelectedMerchant,
  StyledSelectedMerchantIcon,
  StyledSelectedMerchantTitle,
  StyledTooltip,
  StyledCloseIcon,
} from "../index.styles"
import { isBrowser } from "@tmu/utils/auth"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"

const LeftMenu = ({ isUnlisted, onClose }) => {
  const {
    isAuthenticated,
    user,
    isLoading,
    signOut,
    calledPartnerApiPermissions,
    callPartnerApiPermissions,
    calledMerchantApiPermissions,
    callMerchantApiPermissions,
    apiPermissions,
  } = useAuth()
  const { formatMessage } = useIntl()
  const tooltipRef = useRef(null)
  const ref = useRef(null)

  const { pathname, search } = useLocation()
  // const { cartItemCount } = useShoppingCart()
  const { profileImage } = { ...user }
  const [isDashboard, setIsDashboard] = useState(false)
  const [activeMerchant, setActiveMerchant] = useState()
  const referralCode = queryString?.parse(search)?.referral
  const { menuConfig } = useContext(HomePageContext)
  const { defaultPartner } = useDefaultPartner()

  const {
    defaultMerchant,
    isMerchantOffline,
    isMerchantInternal,
    isMerchantOnline,
    isMerchantCorporate,
    loading: isMerchantLoading,
    refetch: merchantRefetch,
  } = useDefaultMerchant()
  const [isClickedOnSwitchAccount, setIsClickedOnSwitchAccount] =
    useState(false)

  useEffect(() => {
    if (isAuthenticated && !user?.id) signOut()
  }, [user])

  const { isWide } = getAllScreenTypes()

  // Create a ref that we add to the element for which we want to detect outside clicks
  useOnClickOutside(ref, () => {
    if (typeof onClose === "function") {
      setTimeout(() => {
        onClose()
      }, 100)
    }
  })

  const profileMenu = user
    ? [
        {
          title: formatMessage({
            id: "dashboard::nav::mySpace",
            defaultMessage: "My Space",
          }),
          link: "/user-space",
          dataTestId: "link-dashboard-user-space",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::profile",
            defaultMessage: "Profile",
          }),
          link: "/dashboard/profile",
          dataTestId: "link-dashboard-profile",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::eventsLink",
            defaultMessage: "Events",
          }),
          link: "/dashboard/user/events",
          dataTestId: "link-dashboard-user-campaigns",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::campaignsLink",
            defaultMessage: "Campaigns",
          }),
          link: "/dashboard/user/campaigns",
          dataTestId: "link-dashboard-user-campaigns",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::donations",
            defaultMessage: "Donations",
          }),
          link: "/dashboard/donors/donations",
          dataTestId: "link-dashboard-donors-donations",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::wallet",
            defaultMessage: "PAC Wallet",
          }),
          link: "/dashboard/donors/wallet",
          dataTestId: "link-dashboard-donors-wallet",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::donationSearch",
            defaultMessage: "Donation Search",
          }),
          link: "/dashboard/donors/donation-search",
          dataTestId: "link-dashboard-donors-donation-search",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::purchases",
            defaultMessage: "Purchases",
          }),
          link: "/dashboard/donors/orders",
          dataTestId: "link-dashboard-donors-orders",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::download",
            defaultMessage: "Download",
          }),
          link: "/dashboard/donors/download-center",
          dataTestId: "link-dashboard-donors-download-center",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::referralCodes",
            defaultMessage: "Referral Codes",
          }),
          link: "/dashboard/donors/referral-codes",
          dataTestId: "link-dashboard-donors-referral-codes",
        },
        {
          title: formatMessage({
            id: "dashboard::nav::donationSettings",
            defaultMessage: "Settings",
          }),
          link: "/dashboard/settings",
          dataTestId: "link-dashboard-donors-donation-settings",
        },
      ]
    : []

  const merchantMenu =
    user?.isMerchant && defaultMerchant
      ? [
          {
            title:
              defaultMerchant?.displayName?.length > 0
                ? defaultMerchant?.displayName
                : defaultMerchant?.name,
            isMenuTitle: true,
          },
        ]
      : []

  if (
    apiPermissions?.length ||
    activeMerchant?.node?.finalPermissions?.length
  ) {
    const permission = (type) =>
      apiPermissions?.includes(type) ||
      activeMerchant?.node?.finalPermissions?.includes(type)

    if (
      user?.isMerchant &&
      permission(API_PERMISSIONS.MERCHANT_VIEW_ACCOUNT_INFORMATION)
    ) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::accountInformation",
          defaultMessage: "Account Information",
        }),
        link: "/dashboard/merchants/account",
        dataTestId: "link-dashboard-merchants-account",
        dataTestIdStatus:
          "link-dashboard-merchants-account_" + defaultMerchant?.status,
      })
    }
    if (
      defaultMerchant?.pricingBundle?.code === "MERCHANT_BRAND" &&
      defaultMerchant?.isDisplayed === true
    ) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::merchantEvents",
          defaultMessage: "Events",
        }),
        link: "/dashboard/merchants/events/",
        dataTestId: "link-dashboard-merchants-events",
      })
    }
    if (user?.isMerchant && permission(API_PERMISSIONS.MERCHANT_MANAGE_USER)) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::userManagement",
          defaultMessage: "User Management",
        }),
        link: "/dashboard/merchants/users",
        dataTestId: "link-dashboard-merchants-users",
      })
    }

    if (
      defaultMerchant?.pricingBundle?.code === "MERCHANT_BRAND" &&
      defaultMerchant?.isDisplayed === true
    ) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::socialImpactPortfolio",
          defaultMessage: "Social Impact Portfolio",
        }),
        link: "/dashboard/merchants/corporate-brands",
        dataTestId: "link-dashboard-corporate-brands",
      })
    }

    if (
      user?.isMerchant &&
      isMerchantOffline &&
      permission(API_PERMISSIONS.MERCHANT_VIEW_CAMPAIGN_LISTING)
    )
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::offlineDiscountCampaigns",
          defaultMessage: "Offline Discount Campaigns",
        }),
        link: "/dashboard/merchants/campaigns?offline=true",
        dataTestId: "link-dashboard-merchants-campaigns",
      })

    if (
      user?.isMerchant &&
      isMerchantInternal &&
      permission(API_PERMISSIONS.MERCHANT_VIEW_PRODUCT_LISTING)
    ) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::merchantsEvents",
          defaultMessage: "Events",
        }),
        link: "/dashboard/merchants/events/event-offers",
        dataTestId: "link-dashboard-merchants-services",
      })
    }
    if (
      user?.isMerchant &&
      isMerchantInternal &&
      permission(API_PERMISSIONS.MERCHANT_VIEW_PRODUCT_LISTING)
    ) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::merchantsServices",
          defaultMessage: "Services",
        }),
        link: "/dashboard/merchants/services",
        dataTestId: "link-dashboard-merchants-services",
      })
    }

    if (user?.isMerchant && isMerchantCorporate) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::merchantsEvents",
          defaultMessage: "Events",
        }),
        link: "/dashboard/merchants/events/?corporate=true",
        dataTestId: "link-dashboard-merchants-campaigns",
      })
    }

    if (user?.isMerchant && isMerchantCorporate) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::campaignsLink",
          defaultMessage: "Campaigns",
        }),
        link: "/dashboard/merchants/campaigns?corporate=true",
        dataTestId: "link-dashboard-merchants-campaigns",
      })
    }

    if (user?.isMerchant && isMerchantOnline) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::storeManagement",
          defaultMessage: "Offer",
        }),
        link: "/dashboard/merchants/stores",
        dataTestId: "link-dashboard-merchants-stores",
      })
    }

    if (
      user?.isMerchant &&
      !isMerchantOffline &&
      !isMerchantCorporate &&
      permission(API_PERMISSIONS.MERCHANT_VIEW_ORDER)
    ) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::sales",
          defaultMessage: "Sales",
        }),
        link: "/dashboard/merchants/sales",
        dataTestId: "link-dashboard-merchants-sales",
      })
    }

    if (
      user?.isMerchant &&
      isMerchantOnline &&
      permission(API_PERMISSIONS.MERCHANT_VIEW_PLUGIN_MANAGEMENT)
    ) {
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::developers",
          defaultMessage: "Developers",
        }),
        link: "/dashboard/merchants/developers",
        dataTestId: "link-dashboard-merchants-developers",
      })
    }

    if (user?.isMerchant)
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::downloadCenter",
          defaultMessage: "Download Center",
        }),
        link: "/dashboard/merchants/download-center",
        dataTestId: "link-dashboard-merchants-download-center",
      })

    if (
      user?.isMerchant &&
      !isMerchantOnline &&
      permission(API_PERMISSIONS.MERCHANT_VIEW_REFERRAL_LINK)
    )
      merchantMenu.push({
        title: formatMessage({
          id: "dashboard::nav::referralCodes",
          defaultMessage: "Referral Codes",
        }),
        link: "/dashboard/merchants/referral-codes",
        dataTestId: "link-dashboard-merchants-referral-codes",
      })
  }

  const partnerMenu =
    user?.isPartner && defaultPartner
      ? [
          {
            title:
              defaultPartner?.displayName?.length > 0
                ? defaultPartner?.displayName
                : defaultPartner?.name,
            isMenuTitle: true,
          },
        ]
      : []

  if (
    user?.isPartner &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_ACCOUNT_INFORMATION)
  )
    partnerMenu.push({
      title: formatMessage({
        id: "dashboard::nav::accountInformation",
        defaultMessage: "Account Information",
      }),
      link: "/dashboard/charities/account",
      dataTestId: "link-dashboard-partners-account",
      dataTestIdStatus:
        "link-dashboard-partners-account_" + defaultPartner?.status,
    })

  // if (user?.isPartner)
  //   partnerMenu.push({
  //     title: formatMessage({
  //       id: "dashboard::nav::wallet",
  //       defaultMessage: "PAC Wallet",
  //     }),
  //     link: "/dashboard/charities/wallet",
  //     dataTestId: "link-dashboard-partners-wallet",
  //   })

  if (
    user?.isPartner &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_CAMPAIGN_LISTING)
  )
    partnerMenu.push({
      title: formatMessage({
        id: "dashboard::nav::campaignsLink",
        defaultMessage: "Campaigns",
      }),
      link: "/dashboard/charities/campaigns",
      dataTestId: "link-dashboard-partners-campaigns",
    })
  if (
    user?.isPartner &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_CAMPAIGN_LISTING)
  )
    partnerMenu.push({
      title: formatMessage({
        id: "dashboard::nav::eventsLink",
        defaultMessage: "Events",
      }),
      link: "/dashboard/charities/events",
      dataTestId: "link-dashboard-partners-campaigns",
    })

  if (
    user?.isPartner &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_WALLET)
  )
    partnerMenu.push({
      title: formatMessage({
        id: "dashboard::nav::donations",
        defaultMessage: "Donations",
      }),
      link: "/dashboard/charities/donations",
      dataTestId: "link-dashboard-partners-donations",
    })

  if (
    user?.isPartner &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_MANAGE_USERS)
  )
    partnerMenu.push({
      title: formatMessage({
        id: "dashboard::nav::userManagement",
        defaultMessage: "User Management",
      }),
      link: "/dashboard/charities/users",
      dataTestId: "link-dashboard-partners-users",
    })

  if (user?.isPartner)
    partnerMenu.push({
      title: formatMessage({
        id: "dashboard::nav::downloadCenter",
        defaultMessage: "Download Center",
      }),
      link: "/dashboard/charities/download-center",
      dataTestId: "link-dashboard-charities-download-center",
    })

  if (
    user?.isPartner &&
    apiPermissions?.includes(API_PERMISSIONS.PARTNER_VIEW_REFERRAL_LINK)
  )
    partnerMenu.push({
      title: formatMessage({
        id: "dashboard::nav::referralCodes",
        defaultMessage: "Referral Codes",
      }),
      link: "/dashboard/charities/referral-codes",
      dataTestId: "link-dashboard-partners-referral-codes",
    })

  useEffect(() => {
    setIsDashboard(/\/(en|it|es)\/dashboard\//.test(pathname))
  }, [pathname])

  const [updateProfile] = useMutation(UPDATE_ME_MUTATION, {
    refetchQueries: [
      {
        query: ME_QUERY,
      },
    ],
    onCompleted: () => {
      merchantRefetch()
      navigate("/dashboard/merchants/account")
    },
  })

  const {
    site: {
      siteMetadata: { supportedLanguages },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            supportedLanguages {
              id
              label
            }
          }
        }
      }
    `
  )

  const merchantUsers = user?.merchantUsers?.edges

  useEffect(() => {
    if (user?.isPartner && !calledPartnerApiPermissions) {
      callPartnerApiPermissions()
      return
    }

    const activeMerchantTemp =
      merchantUsers?.length === 1
        ? merchantUsers[0]
        : merchantUsers?.find(
            (merchant) =>
              merchant?.node?.store?.id === user?.activeStore?.id ||
              merchant?.node?.store?.id === defaultMerchant?.id
          )

    setActiveMerchant(activeMerchantTemp)

    if (
      user?.isMerchant &&
      activeMerchantTemp?.node?.id &&
      !calledMerchantApiPermissions
    ) {
      callMerchantApiPermissions({
        variables: { id: activeMerchantTemp?.node?.id },
      })
    }
  }, [user?.activeStore?.id, defaultMerchant?.id, defaultPartner?.id])

  const handleSignIn = () => {
    onClose()
    const next = isBrowser
      ? window?.location?.pathname + window?.location?.search
      : "/"

    navigate(
      `/signin?${
        referralCode && referralCode !== "undefined"
          ? `referral="${referralCode}&`
          : ""
      }next=${next}`
    )
  }

  const handleSignUp = () => {
    onClose()
    const next = isBrowser
      ? window?.location?.pathname + window?.location?.search
      : "/"
    navigate(
      `/signup?${
        referralCode && referralCode !== "undefined"
          ? `referral="${referralCode}&`
          : ""
      }next=${next}`
    )
  }

  const handleSignOut = () => {
    signOut()
    onClose()
  }

  const profileImageSize = isWide ? 40 : 24
  const dashboardStyle =
    menuConfig?.forceOverlay === true
      ? ""
      : isDashboard
      ? "overlay-dashboard"
      : ""

  useEffect(() => {
    if (ref.current) {
      if (isClickedOnSwitchAccount) {
        ref.current.style.overflow = "visible"
      } else {
        ref.current.style.overflow = "auto"
      }
    }
  }, [isClickedOnSwitchAccount])

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (tooltipRef.current && !tooltipRef.current.contains(event.target))
  //       setIsClickedOnSwitchAccount(false)
  //   }
  //   document.addEventListener("mousedown", handleClickOutside)

  //   return () => document.removeEventListener("mousedown", handleClickOutside)
  // }, [])

  const isMultipleStore = merchantUsers?.length > 1
  const activeStore = activeMerchant?.node?.store

  const style = { padding: "0 -1.5rem -1.5rem", borderRadius: "0.5rem" }

  return (
    !isLoading && (
      <StyledMenuOverlay
        className={!isUnlisted ? "hidden" : `${dashboardStyle}`}
        isDashboard={isDashboard}>
        <StyledMobileMenu
          data-testid="mobile-menu"
          ref={ref}
          className={!isUnlisted ? "hidden" : ""}>
          <StyledLeftMenuItemList>
            {user?.id && (
              <StyledLeftMenuItem>
                <StyledAvatar>
                  {profileImage?.length ? (
                    <img
                      src={`${profileImage}?width=${profileImageSize}&height=${profileImageSize}`}
                      alt="avatar"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUserAlt} />
                  )}
                </StyledAvatar>
                <StyledLeftMenuUserInfo>
                  {user?.fullName}
                </StyledLeftMenuUserInfo>
              </StyledLeftMenuItem>
            )}

            {!user?.id && (
              <StyledLeftMenuItem>
                <StyledMobileAuthSection>
                  <StyledLeftSigninButton
                    to={`/signin?${
                      referralCode && referralCode !== "undefined"
                        ? `referral="${referralCode}&`
                        : ""
                    }next=${
                      isBrowser
                        ? window?.location?.pathname + window?.location?.search
                        : "/"
                    }`}
                    onClick={handleSignIn}>
                    <FormattedMessage
                      id="nav::signin"
                      defaultMessage="Sign In"
                    />
                  </StyledLeftSigninButton>
                  {/* <CartLink cartItemCount={cartItemCount} anonymous={true} /> */}
                  <StyledLeftGetStartedButton
                    to="/signup"
                    color={theme.colors.pacificBlue}
                    onClick={handleSignUp}
                    data-testid="btn-get-started">
                    <FormattedMessage
                      id="nav::getStarted"
                      defaultMessage="Get Started"
                    />
                  </StyledLeftGetStartedButton>
                </StyledMobileAuthSection>
              </StyledLeftMenuItem>
            )}

            {user?.id &&
              profileMenu?.map((item, index) => {
                return (
                  <StyledLeftMenuItem key={"profile" + index}>
                    <StyledMobileNavButton
                      data-testid={item?.dataTestId}
                      data-testid-status={item?.dataTestIdStatus}
                      variant="text"
                      className={pathname?.endsWith(item?.link) ? "active" : ""}
                      onClick={() => navigate(item?.link)}>
                      {item?.title}
                    </StyledMobileNavButton>
                  </StyledLeftMenuItem>
                )
              })}
            {user?.id && (
              <StyledLeftMenuItem>
                <StyledMobileNavButton variant="text" onClick={handleSignOut}>
                  <FormattedMessage
                    id="nav::logout"
                    defaultMessage="Sign Out"
                  />
                </StyledMobileNavButton>
              </StyledLeftMenuItem>
            )}
            {user?.id && merchantMenu?.length > 0 && <hr />}
            {isMerchantLoading ? (
              <Spinner condensed className="slim" />
            ) : (
              user?.id &&
              merchantMenu?.map((item, index) => {
                return (
                  <StyledLeftMenuItem key={"merchant" + index}>
                    {item?.isMenuTitle ? (
                      <>
                        <StyledMerchantsTypeWrapper>
                          <StyledTooltip className="tooltip">
                            <StyledLeftMenuTextWithIconWrapper
                              onClick={() => setIsClickedOnSwitchAccount(true)}
                              isMultipleMerchant={isMultipleStore}>
                              <StyledLeftMenuUserInfo>
                                {activeMerchant?.node?.store?.name ||
                                  item?.title}
                              </StyledLeftMenuUserInfo>

                              {isMultipleStore && (
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  onClick={() =>
                                    setIsClickedOnSwitchAccount(false)
                                  }
                                />
                              )}
                            </StyledLeftMenuTextWithIconWrapper>

                            {isMultipleStore && (
                              <span
                                ref={tooltipRef}
                                className={
                                  isClickedOnSwitchAccount
                                    ? "tooltipselect"
                                    : "tooltiptext"
                                }>
                                {isClickedOnSwitchAccount ? (
                                  <>
                                    <CustomModal
                                      isModalOpen={isClickedOnSwitchAccount}
                                      style={style}
                                      header={""}
                                      isWithoutHeader={true}
                                      text={<></>}
                                      children={
                                        <>
                                          <StyledMerchantsWrapper>
                                            <StyledCloseIcon>
                                              <FontAwesomeIcon
                                                icon={faXmark}
                                                onClick={() =>
                                                  setIsClickedOnSwitchAccount(
                                                    false
                                                  )
                                                }
                                              />
                                            </StyledCloseIcon>
                                            <StyledSelectedMerchant>
                                              <StyledSelectedMerchantIcon>
                                                {activeStore?.logo ? (
                                                  <img
                                                    src={activeStore?.logo}
                                                    height={"100%"}
                                                    width={"100%"}
                                                    alt="Merchant Logo"
                                                  />
                                                ) : (
                                                  activeStore?.name?.[0] ||
                                                  item?.title?.[0]
                                                )}
                                              </StyledSelectedMerchantIcon>
                                              <div>
                                                <StyledSelectedMerchantTitle>
                                                  {activeStore?.name ||
                                                    item?.title}
                                                </StyledSelectedMerchantTitle>
                                                <StyledMerchantStatus>
                                                  {
                                                    activeStore?.defaultOffer
                                                      ?.offerType
                                                  }
                                                </StyledMerchantStatus>
                                              </div>
                                            </StyledSelectedMerchant>

                                            <StyledMerchantOptions>
                                              {merchantUsers
                                                ?.filter(
                                                  (merchant) =>
                                                    ![
                                                      activeStore?.id,
                                                      defaultMerchant?.id,
                                                    ].includes(
                                                      merchant?.node?.store?.id
                                                    )
                                                )
                                                ?.map((merchant) => (
                                                  <StyledMerchant
                                                    onClick={async () => {
                                                      await updateProfile({
                                                        variables: {
                                                          input: {
                                                            activeStore:
                                                              merchant?.node
                                                                ?.store?.id,
                                                          },
                                                        },
                                                      }).then((r) => {
                                                        setActiveMerchant(
                                                          merchant
                                                        )
                                                      })
                                                      setIsClickedOnSwitchAccount(
                                                        false
                                                      )
                                                    }}>
                                                    <StyledMerchantIcon>
                                                      {merchant?.node?.store
                                                        ?.logo ? (
                                                        <img
                                                          src={
                                                            merchant?.node
                                                              ?.store?.logo
                                                          }
                                                          height={"100%"}
                                                          width={"100%"}
                                                          alt="Merchant Logo"
                                                        />
                                                      ) : (
                                                        merchant?.node?.store
                                                          ?.name?.[0]
                                                      )}
                                                    </StyledMerchantIcon>
                                                    <div>
                                                      <StyledMerchantTitle>
                                                        {
                                                          merchant?.node?.store
                                                            ?.name
                                                        }
                                                      </StyledMerchantTitle>
                                                      <StyledMerchantStatus>
                                                        {
                                                          merchant?.node?.store
                                                            .defaultOffer
                                                            ?.offerType
                                                        }
                                                      </StyledMerchantStatus>
                                                    </div>
                                                  </StyledMerchant>
                                                ))}
                                            </StyledMerchantOptions>
                                          </StyledMerchantsWrapper>
                                        </>
                                      }
                                      isCloseIcon={true}
                                      cancelButtonAction={() => {}}
                                      isMobile={false}
                                      isFull={false}
                                    />
                                  </>
                                ) : (
                                  <FormattedMessage
                                    id="nav::switchAccount"
                                    defaultMessage="Switch Account"
                                  />
                                )}
                              </span>
                            )}
                          </StyledTooltip>
                          <StyledMerchantType>
                            {activeStore?.defaultOffer?.offerType}
                          </StyledMerchantType>
                        </StyledMerchantsTypeWrapper>
                      </>
                    ) : (
                      <StyledMobileNavButton
                        data-testid={item?.dataTestId}
                        data-testid-status={item?.dataTestIdStatus}
                        variant="text"
                        className={
                          pathname?.endsWith(item?.link) ? "active" : ""
                        }
                        onClick={() => navigate(item?.link)}>
                        {item?.title}
                      </StyledMobileNavButton>
                    )}
                  </StyledLeftMenuItem>
                )
              })
            )}
            {user?.id && partnerMenu?.length > 0 && <hr />}
            {user?.id &&
              partnerMenu?.map((item, index) => {
                return (
                  <StyledLeftMenuItem key={"partner" + index}>
                    {item?.isMenuTitle ? (
                      <StyledLeftMenuUserInfo>
                        {item?.title}
                      </StyledLeftMenuUserInfo>
                    ) : (
                      <StyledMobileNavButton
                        data-testid={item?.dataTestId}
                        data-testid-status={item?.dataTestIdStatus}
                        variant="text"
                        className={
                          pathname?.endsWith(item?.link) ? "active" : ""
                        }
                        onClick={() => navigate(item?.link)}>
                        {item?.title}
                      </StyledMobileNavButton>
                    )}
                  </StyledLeftMenuItem>
                )
              })}
          </StyledLeftMenuItemList>
        </StyledMobileMenu>
      </StyledMenuOverlay>
    )
  )
}

export default LeftMenu
