import { Wrapper } from "./index.styles"
import NumericStepper from "../NumericStepper"
import { useDonations } from "@tmu/src/hooks"
import { useEffect, useState } from "react"
import { BaseError, FieldError } from "../ErrorMessage"
import { useIntl } from "gatsby-plugin-intl"

const AmountProvisioner = ({
  amount,
  tier,
  onError,
  onChange,
  isService,
  expectedQuantityParam,
  disabled,
  isUnlimited,
  rest,
}) => {
  const { callDonationProvision, donationProvisionData } = useDonations()
  const { loading, provisionData } = donationProvisionData
  const expectedQuantity =
    provisionData?.expectedQuantity ?? expectedQuantityParam
  const [quantity, setQuantity] = useState()
  const [isError, setIsError] = useState(false)
  const [initialValue, setInitialValue] = useState(1)
  const { formatMessage } = useIntl()

  const callProvision = (value) => {
    if (!amount) return

    return callDonationProvision({
      variables: {
        input: {
          tier,
          amount: amount * value,
          quantity: value,
        },
      },
    })
  }

  useEffect(() => {
    if (
      expectedQuantity > 0 &&
      expectedQuantity < quantity &&
      typeof onError === "function"
    ) {
      onError(expectedQuantity)
      setInitialValue(expectedQuantity)
      setIsError(true)
    }
  }, [expectedQuantity, quantity])

  const errorText = formatMessage({
    defaultMessage: "Available quantity is",
    id: "amount::provision::quantity",
  })

  const showError = isError === true && !isUnlimited

  return (
    <Wrapper className={showError ? "error" : ""}>
      <NumericStepper
        {...rest}
        className={showError ? "error" : ""}
        disabled={loading || disabled}
        onChange={(value) => {
          setIsError(false)
          if (!isService && !isUnlimited) {
            callProvision(value)?.then((res) => {
              const result =
                res?.data?.createDonationProvision?.donationProvision
              if (typeof onChange === "function") {
                onChange(value, expectedQuantity, result)
              }
            })
          } else {
            onChange(value, expectedQuantity)
          }
          setQuantity(value)
        }}
        initialValue={initialValue}
        maximumValue={isUnlimited ? null : expectedQuantityParam}
      />
      {showError && (
        <>
          <BaseError message={`${errorText} ${expectedQuantity}`} />
        </>
      )}
    </Wrapper>
  )
}

export default AmountProvisioner
