import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareAlt as faShare } from "@fortawesome/pro-regular-svg-icons/faShareAlt"
import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload"
import { Money, ShareComponent } from "@tmu/components/common"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Fragment } from "react"
import { Tooltip } from "react-tooltip"
import { useAuth, useDonorList } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import {
  StyledCampaignStats,
  StyledGoallessCampaignStats,
} from "@tmu/components/partners/PartnerDetail/index.styles"
import { StyledOfflineMerchantTooltip } from "./index.styles"
import theme from "@tmu/global/theme"

const Stats = ({
  isTablet,
  canSupporterEditCampaign,
  fundedAmount,
  donationCount,
  campaignId,
  campaignName,
  isMerchantCampaign,
  slug,
  campaign,
  isNewCampaign,
  isOfflineMerchant,
  isCorporateMerchant
}) => {
  const { formatMessage } = useIntl()
  const { apiPermissions } = useAuth()
  const { getSupporterDonorList, getOfflineMerchantDonorList } = useDonorList()

  const handleSupporterDonorListClick = () =>
    getSupporterDonorList({
      variables: {
        input: {
          campaign: campaignId,
        },
      },
    })

  const handleOfflineMerchantDonorListClick = () =>
    getOfflineMerchantDonorList({
      variables: {
        input: {
          campaign: campaignId,
        },
      },
    })

  const totalSupportText = formatMessage(
    {
      id: "progress::detail::totalSupport",
      defaultMessage: "<funded>{amount}</funded> Raised",
    },
    {
      funded: (...chunks) => (
        <span key="funded" className="funded">
          {chunks.map((chunk, i) => (
            <Fragment key={i}>{chunk}</Fragment>
          ))}
        </span>
      ),
      amount: <Money value={fundedAmount} maximumFractionDigits={0} />,
    }
  )
  return (
    <StyledCampaignStats>
      <StyledGoallessCampaignStats isTablet={isTablet}>
        <div>
          <p className="caption">
            <FormattedMessage
              id="progress::supporterCount"
              defaultMessage="{current, plural, =0 {No Supporters} =1 {<one>#</one> Supporter} other {<num>#</num> Supporters}}"
              values={{
                one: (...chunks) => <span className="support">1</span>,
                num: (...chunks) => (
                  <span key="current" className="funded">
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>{chunk}</Fragment>
                    ))}
                  </span>
                ),
                current: donationCount,
              }}
            />
            {canSupporterEditCampaign && !isMerchantCampaign && (
              <>
                <Tooltip
                  arrowColor="transparent"
                  effect="solid"
                  place="top"
                  type="info"
                  textColor="white"
                  backgroundColor="rgba(10, 157, 189, 1)"
                  id="download"
                  delayShow={500}
                />
                <FontAwesomeIcon
                  data-tooltip-content={formatMessage({
                    id: "campaign::detail::downloadDonorList",
                    defaultMessage: "Download Donor List",
                  })}
                  data-tooltip-id="download"
                  className="download"
                  icon={faDownload}
                  onClick={handleSupporterDonorListClick}
                />
              </>
            )}
            {!canSupporterEditCampaign &&
              isMerchantCampaign &&
              apiPermissions?.includes(
                API_PERMISSIONS.MERCHANT_VIEW_REPORTS
              ) && (
                <>
                  <StyledOfflineMerchantTooltip>
                    <Tooltip
                      arrowColor="transparent"
                      effect="solid"
                      place="bottom"
                      type="info"
                      textColor="black"
                      backgroundColor="white"
                      id="download"
                      className="offline-merchant"
                      delayShow={500}
                    />
                  </StyledOfflineMerchantTooltip>
                  <FontAwesomeIcon
                    data-tooltip-content={formatMessage({
                      id: "campaign::detail::downloadOfflineMerchantDonorList",
                      defaultMessage: "Download a report",
                    })}
                    data-tooltip-id="download"
                    className="download"
                    icon={faDownload}
                    onClick={handleOfflineMerchantDonorListClick}
                  />
                </>
              )}
          </p>
          <p className="caption right">{totalSupportText}</p>
        </div>
        {!isTablet && (
          <div>
            <ShareComponent
              className={"mt-0"}
              slug={slug}
              campaignId={campaign?.id}
              isNewCampaign={isNewCampaign}
              name={campaign?.name}
              isMerchantCampaign={isMerchantCampaign}
              discount={campaign?.store?.maxPacDiscount}
              storeLogo={campaign?.store?.logo}
              partnerLogo={campaign?.partner?.logo}
              position="center"
              iconColor={
                isOfflineMerchant
                  ? theme?.colors?.carrot
                  : isCorporateMerchant
                  ? theme?.colors?.carrot
                  : theme?.colors?.blue
              }
            />
          </div>
        )}
      </StyledGoallessCampaignStats>
    </StyledCampaignStats>
  )
}

export default Stats
