import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { Logo, Money } from "@tmu/components/common"
import theme from "@tmu/global/theme"
import {
  StyledOfferStats,
  StyledPriceContent,
  StyledPrice,
  StyledMaxPacDiscountContent,
  StyledMaxPacDiscount,
} from "./index.styles"

const OfferStats = ({ price, maxPacDiscount, showSetPrice }) => {
  const { formatMessage } = useIntl()
  const { isDesktop, isWide } = getAllScreenTypes()

  return (
    <StyledOfferStats>
      <StyledPriceContent>
        <StyledPrice>
          {showSetPrice ? (
            <span className="caption set">
              {formatMessage({
                id: "progress::productVariation::setPrice",
                defaultMessage: "Set price",
              })}
            </span>
          ) : (
            <span>
              <Money value={Number(price ?? 0)} maximumFractionDigits={0} />
            </span>
          )}
          <span className="caption">
            {formatMessage({
              id: "progress::productVariation::price",
              defaultMessage: "PRICE",
            })}
          </span>
        </StyledPrice>
      </StyledPriceContent>
      <StyledMaxPacDiscountContent>
        <StyledMaxPacDiscount>
          <span>
            <Money
              style="percent"
              value={Number(maxPacDiscount ?? 0)}
              currency={false}
            />
          </span>
        </StyledMaxPacDiscount>
        <span className="caption">
          {formatMessage({
            id: "progress::offer::maxPacDiscount",
            defaultMessage: "PAY WITH PACS",
          })}
        </span>
      </StyledMaxPacDiscountContent>
    </StyledOfferStats>
  )
}

OfferStats.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  maxPacDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default OfferStats
