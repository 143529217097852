import React from "react"
import EventListing from "./EventListing"
import ServiceEventListing from "./EventListing/ServiceEventListing"
import EventDetail from "./EventDetail"
import ServiceEventDetail from "./EventDetail/ServiceEventDetail"
import { Router } from "@reach/router"
import OfferTypes from "../OfferTypes"

export default function Services({ dashboardType }) {
  return (
    <Router>
      <EventListing path="/" dashboardType={dashboardType} />
      <ServiceEventListing path="/event-offers" dashboardType={dashboardType} />
      <ServiceEventDetail
        path="/event-offers/detail/:id"
        dashboardType={dashboardType}
      />
      <EventDetail path="/detail/:slug" dashboardType={dashboardType} />
      <OfferTypes path="/type" />
    </Router>
  )
}
