const generateGoogleMapsLinkAndOpen = (address, name) => {
  console.log('address', address)
  if (navigator.geolocation) {
    getEndUserLatAndLong()
      .then((endUserCoords) => {
        if (endUserCoords === undefined) endUserCoords = ""

        const formattedAddress = encodeURIComponent(address)
        return window.open(
          `${process.env.GOOGLE_MAPS_URL}/dir/${endUserCoords}/${formattedAddress}`,
          name
        )
      })
      .catch((err) => {
        return err
      })
  }
}

const generateGoogleMapsEmbedLink = (address, language) => {
  const formattedAddress = encodeURIComponent(address)
  return `${process.env.GOOGLE_MAPS_URL}/embed/v1/place?key=${process.env.GOOGLE_MAPS_API_KEY}&q=${formattedAddress}&language=${language}`
}

const getEndUserLatAndLong = async () => {
  try {
    let position = await getEndUserLocations()
    const { coords } = position

    return `${coords?.latitude},${coords?.longitude}`
  } catch (err) {}
}

const getEndUserLocations = () => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject)
  )
}

export { generateGoogleMapsLinkAndOpen, generateGoogleMapsEmbedLink }
