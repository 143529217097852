import React from "react"
import PropTypes from "prop-types"
import { StyledLabel, StyledInput, StyledCheckmark } from "./index.styles"

const Checkbox = ({ label, id, isChecked, onChange, className, ...rest }) => {
  return (
    <StyledLabel
      key={id}
      className={`${className} ${rest?.disabled ? "disabled" : ""}`}>
      {label}
      <StyledInput
        {...rest}
        disabled={rest?.disabled}
        type="checkbox"
        checked={isChecked}
        value={id}
        onChange={(evt) => {
          console.log('evt', evt.target.value)
          onChange(evt.target.value)
        }}
      />
      <StyledCheckmark className="checkmark" />
    </StyledLabel>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  label: "",
  id: "",
  isChecked: false,
  onChange: () => {},
}

export default Checkbox
