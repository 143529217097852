import React, { Fragment } from "react"
import { ServiceEventsTable } from "@tmu/components/dashboard/dashboardCommon"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import {
  StyledForm,
  StyledSearchContainer,
} from "../../DonationSearch/index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { FormattedMessage, useIntl, navigate, Link } from "gatsby-plugin-intl"
import { useState } from "react"
import { Button, Money } from "@tmu/components/common"
import { StyledCreate, StyledMainText } from "./index.styles"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useAuth } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import { useDefaultMerchant, useReferral, useDefaultPartner } from "@tmu/hooks"

export default function EventListing({ dashboardType }) {
  const [eventName, setEventName] = useState("")
  const [searchText, setSearchText] = useState()
  const { formatMessage } = useIntl()
  const {
    apiPermissions,
    callMerchantApiPermissions,
    calledMerchantApiPermissions,
    user,
  } = useAuth()
  const { isTablet } = getAllScreenTypes()
  let searchTimeout = null
  const { defaultMerchant } = useDefaultMerchant()
  const { defaultPartner } = useDefaultPartner()
  const totalSoldTickets = defaultMerchant?.inventoryRecordByOffer?.booked


  const handleCreateEvent = () => {
    dashboardType === "donors"
      ? navigate("/dashboard/campaign/new/?type=event&supporter=true")
      : dashboardType === "merchants"
      ? navigate("/dashboard/merchants/services/new/?event=true")
      : dashboardType === "partners"
      ? navigate("/dashboard/campaign/new/?type=event")
      : "/dashboard/profile/"
  }

  // useEffect(() => {
  //   if (!apiPermissions?.length || !calledMerchantApiPermissions) {
  //     callMerchantApiPermissions()
  //   }
  // }, [apiPermissions, calledMerchantApiPermissions])

  const createText = (
    <FormattedMessage
      id="dashboard::events::createEvent"
      defaultMessage="Create an Event"
    />
  )

  return (
    <StyledPage>
      <StyledPageTitle>
        <div>
          <FormattedMessage
            id="dashboard::nav::events"
            defaultMessage="Events"
            tagName="h1"
          />
        </div>
        {isTablet && (
          <StyledCreate carrot={dashboardType === "merchants"}>
            <div>
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div onClick={handleCreateEvent}>{createText}</div>
          </StyledCreate>
        )}
      </StyledPageTitle>
      <StyledMainText carrot={dashboardType === "merchants"}>
        <FormattedMessage
          id="dashboard::events::mainText"
          defaultMessage="<pacs>{amount}</pacs> tickets have been sold. Keep it up!"
          values={{
            pacs: (...chunks) => (
              <span key="processedText">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </span>
            ),
            amount: totalSoldTickets || 0,
          }}
        />
        <hr />
      </StyledMainText>
      <StyledPageContent data-testid="events-page-content">
        <ServiceEventsTable
          searchText={searchText}
          apiPermissions={apiPermissions}
          dashboardType={dashboardType}
        />
        <br />
      </StyledPageContent>

      {!isTablet && (
        <div className="sticky-bottom-bar">
          <Button
            data-testid="btn-create-event"
            color="transparent"
            type="button"
            onClick={handleCreateEvent}>
            <FontAwesomeIcon icon={faPlus} />
            {createText}
          </Button>
        </div>
      )}
    </StyledPage>
  )
}
