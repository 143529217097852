import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react"
import PropTypes from "prop-types"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import { useQuery } from "@apollo/client"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { /* useCountries, */ useLogoImage } from "@tmu/hooks"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp"
import { faShareAlt as faShare } from "@fortawesome/pro-regular-svg-icons/faShareAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, differenceInCalendarDays } from "date-fns"
import { dateLocales } from "@tmu/src/apollo/constants"
import {
  Spinner,
  MediaSlider,
  RadioSelect,
  Button,
  OfferStats,
  ShareComponent,
  DetailHeader,
  UseBefore,
  LocationViewer,
} from "@tmu/components/common"
import ServiceListing from "@tmu/components/services/ServiceListing"
import ContentNotFound from "@tmu/components/ContentNotFound"
import SEO from "@tmu/components/seo"
import {
  StyledProductVariationOptions,
  StyledWrapper,
  StyledContent,
  StyledContent2,
  StyledPageWrapper,
  StyledCongratsPopup,
  StyledCongratsMobile,
  StyledContactTitle,
  StyledPhoneBox,
  StyledLocationBox,
  StyledDetailHeader,
  StyledStoreLogoContainer,
  StyledStoreLogo,
  StyledStoreName,
  StyledMapMainContainer,
  StyledMapContainer,
  StyledMapGetDirections,
  StyledMapHideShow,
  StyledHeaderAndShare,
  StyledEventLocationInfo,
  StyledEventTimeInfo,
  StyledCampaignSubtitle,
} from "./index.styles"
import { OFFER_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/offer"
import {
  StyledSectionTitle,
  StyledPartnerDescription as StyledDescription,
  StyledSupportButton,
} from "@tmu/components/partners/PartnerDetail/index.styles"
import {
  DetailPageContainer,
  DetailPageMobileContainer,
  MainPart,
  SidePart,
  Spacer,
} from "@tmu/global/page-addons/detail-page.styles"
import theme from "@tmu/global/theme"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { getValueForLocale } from "@tmu/utils/string"
import { getFormatPhoneNumber } from "@tmu/utils/formatPhoneNumber"
import {
  generateGoogleMapsLinkAndOpen,
  generateGoogleMapsEmbedLink,
} from "@tmu/utils/googleMaps"
import { useFooterType } from "@tmu/hooks"
import { HomePageContext } from "@tmu/context/homePageContext"
import queryString from "query-string"
import {
  StyledExpiryDate,
  StyledUseBefore,
} from "../../common/UseBefore/index.styles"

const ServiceDetail = ({ slug = "", location }) => {
  const { pathname, search } = location
  const isNewService = queryString.parse(search)?.new === "true"
  const referralCode = queryString.parse(search)?.referral

  const { locale, defaultLocale, formatMessage } = useIntl()
  const { setIntercomStatus } = useContext(HomePageContext)
  const [productVariationRadioOptions, setProductVariationRadioOptions] =
    useState([])
  const [selectedProductVariation, setSelectedProductVariation] = useState({})
  const [productVariationSelectValue, setProductVariationSelectValue] =
    useState("1")
  // const { countryOptions } = useCountries()
  const [isMapVisible, setIsMapVisible] = useState(false)

  const { loading, data } = useQuery(OFFER_DETAIL_QUERY({ locale }), {
    variables: { slug },
    fetchPolicy: "network-only",
    onCompleted: () => {
      setTimeout(function () {
        if (typeof window !== "undefined") {
          window.prerenderReady = true
        }
      }, 500)
    },
  })

  const logoImage = useLogoImage()

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const productVariationOptions = data?.offer?.productVariation?.edges || []

  useEffect(() => {
    setIntercomStatus({ hide: true })
  }, [])

  useFooterType({
    footerType: !isTablet ? FOOTER_TYPE.HIDE : FOOTER_TYPE.TIGHT,
  })

  useEffect(() => {
    const radioOptions = productVariationOptions
      .filter((item) => !item?.node?.allowCustomAmount)
      .map((item) => {
        const labelText = getValueForLocale(
          item?.node,
          "name",
          locale,
          defaultLocale
        )

        const descriptionText = getValueForLocale(
          item?.node,
          "description",
          locale,
          defaultLocale
        )

        return {
          id: item?.node?.id,
          pacDiscount: item?.node?.pacDiscount,
          isUnlimitedTickets: item?.node?.isUnlimitedTickets,
          isSoldOut: item?.node?.isSoldOut,
          label: labelText,
          price: item?.node?.price,
          description: descriptionText,
          allowCustomAmount: item?.node?.allowCustomAmount,
        }
      })

    if (radioOptions.length) {
      radioOptions.sort((a, b) => a?.price - b?.price)
    }
    const allowCustomAmountOption = productVariationOptions.filter(
      (item) => item?.node?.allowCustomAmount
    )?.[0]
    if (allowCustomAmountOption) {
      radioOptions.push({
        id: allowCustomAmountOption?.node?.id,
        label: formatMessage({
          id: `donation::option::${!isTablet ? "setOtherAmount" : "setAmount"}`,
          defaultMessage: !isTablet ? "Other" : "I will set an amount",
        }),
        pacDiscount: allowCustomAmountOption?.node?.pacDiscount,
        isUnlimitedTickets: allowCustomAmountOption?.node?.isUnlimitedTickets,
        isSoldOut: allowCustomAmountOption?.node?.isSoldOut,
        price: 0,
        description: getValueForLocale(
          allowCustomAmountOption?.node,
          "description",
          locale,
          defaultLocale
        ),
        allowCustomAmount: allowCustomAmountOption?.node?.allowCustomAmount,
      })
    }
    if (radioOptions.length) {
      const selectedItem = radioOptions.reduce((prev, current) => {
        var prevPrice = parseFloat(prev.price)
        var currentPrice = parseFloat(current.price)
        return currentPrice < prevPrice ? current : prev
      })
      setSelectedProductVariation(selectedItem)
      setProductVariationSelectValue(selectedItem?.id)
      setProductVariationRadioOptions(radioOptions)
    }
  }, [data, data?.offer?.productVariation?.edges, isTablet])

  const handleModalOpenClose = () => {
    navigate(`/services/${slug}`)
  }

  const handleSupportClick = () => {
    navigate(
      `/services/${slug}/checkout?variant=${selectedProductVariation?.id}${
        referralCode ? "&referral=" + referralCode : ""
      }${selectedProductVariation?.allowCustomAmount ? "&set=true" : ""}`
    )
  }

  const offer = data?.offer
    ? {
        ...data.offer,
        name: getValueForLocale(data.offer, "name", locale, defaultLocale),
        description: getValueForLocale(
          data.offer,
          "description",
          locale,
          defaultLocale
        ),
        shortDescription: getValueForLocale(
          data.offer,
          "shortDescription",
          locale,
          defaultLocale
        ),
      }
    : null

  const createOfferImages = (imageUrls) => {
    if (typeof imageUrls === "object" || Array.isArray(imageUrls)) {
      return imageUrls?.map((imageUrl, index) => ({
        id: index + 1,
        image: imageUrl,
        description: "",
      }))
    }

    return []
  }

  const offerImages = createOfferImages(Array.of(offer?.image))

  const offerImage = offerImages?.length
    ? `${offerImages[0].image}?width=600&height=317&quality=90`
    : offer?.image || logoImage

  const supportButtonText = formatMessage({
    id: "service::detail::buttons::buy",
    defaultMessage: "Buy",
  })

  const getEventDate = useCallback(() => {
    const eventStartsAt = new Date(offer?.eventStartsAt)

    const timeDiff = eventStartsAt.getTime() - Date.now()

    if (timeDiff > 0) {
      const dayDiff = differenceInCalendarDays(eventStartsAt, new Date())

      const formattedDate = format(eventStartsAt, "dd/MM/yyyy", {
        locale: dateLocales[locale],
      })
      const formattedTime = format(eventStartsAt, "HH:mm", {
        locale: dateLocales[locale],
      })

      const dateData = {
        daysLeft: dayDiff,
        endDate: formattedDate,
        hour: formattedTime,
      }
      return dateData
    }
    return null
  }, [data, offer, isTablet])

  const EventTimeInfo = useCallback(() => {
    if (offer?.isEventOffer && offer?.eventStartsAt) {
      return (
        <StyledEventTimeInfo>
          <p className="caption single">
            {" "}
            <FormattedMessage
              id="service::detail::eventDate"
              defaultMessage="Event Date"
            />{" "}
            <span>
              {format(new Date(offer?.eventStartsAt), "MMMM do yyyy", {
                locale: dateLocales[locale],
              })}
            </span>
          </p>
          <p className="caption single">
            <FormattedMessage
              id="service::detail::eventTime"
              defaultMessage="Event Time"
            />{" "}
            <span>
              {format(new Date(offer?.eventStartsAt), "hh:mm", {
                locale: dateLocales[locale],
              })}
            </span>
          </p>
        </StyledEventTimeInfo>
      )
    }
    const eventDate = getEventDate()

    if (!eventDate) return null
    return (
      <>
        {!isTablet ? (
          <StyledEventTimeInfo data-testid="event-time-info">
            <p className="caption">
              <FormattedMessage
                id={"event::timeInfo"}
                defaultMessage={
                  "{daysLeft, plural, =0 {<strong>today</strong>} =1 {<strong>tomorrow</strong>} other {<strong>{daysLeft} days left</strong> until <strong>{endDate}</strong>}}, starts at <strong>{hour}</strong>"
                }
                values={{
                  daysLeft: eventDate?.daysLeft,
                  endDate: eventDate?.endDate,
                  hour: eventDate?.hour,
                  strong: (...chunks) => (
                    <span>
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </span>
                  ),
                }}
              />
            </p>
          </StyledEventTimeInfo>
        ) : (
          <StyledEventTimeInfo data-testid="event-time-info">
            <p className="caption">
              <FormattedMessage
                id="event::timeInfoDay"
                defaultMessage="{daysLeft, plural, =0 {<strong>today</strong>} =1 {<strong>TOMORROW</strong>} other {<strong>{daysLeft} days left</strong> until <strong>{endDate}</strong>}}"
                values={{
                  daysLeft: eventDate?.daysLeft,
                  endDate: eventDate?.endDate,
                  strong: (...chunks) => (
                    <bold className="caption">
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </bold>
                  ),
                }}
              />
            </p>
            <p className="caption">
              <FormattedMessage
                id="event::timeInfoHour"
                defaultMessage="starts at <strong>{hour}</strong>"
                values={{
                  hour: eventDate?.hour,
                  strong: (...chunks) => (
                    <bold className="caption">
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>{chunk}</Fragment>
                      ))}
                    </bold>
                  ),
                }}
              />
            </p>
          </StyledEventTimeInfo>
        )}
      </>
    )
  }, [data, offer])

  const DefaultSubtitle = useCallback(() => {
    return (
      <StyledCampaignSubtitle eventDate={!!getEventDate()}>
        <div>
          <p className="caption">
            <FormattedMessage
              id="dashboard::campaignType::event"
              defaultMessage="Event"
            />
          </p>
          <EventTimeInfo />
        </div>
      </StyledCampaignSubtitle>
    )
  }, [offer])

  const headerComponent = (
    <StyledDetailHeader>
      <div className="flex">
        <DetailHeader
          headerClassName="detail-header"
          text={getValueForLocale(data?.offer, "name", locale, defaultLocale)}
        />
        {!isTablet && (
          <div>
            <ShareComponent
              className="mt-0"
              offerId={offer?.id}
              slug={slug}
              isNewCampaign={isNewService}
              name={offer?.name}
              discount={offer?.store?.maxPacDiscount}
              storeLogo={offer?.store?.logo}
              position="center"
            />
          </div>
        )}
      </div>
      {offer?.isEventOffer ? <DefaultSubtitle /> : null}
    </StyledDetailHeader>
  )

  /* const getCountryLabel = useCallback(
    (countryCode) => {
      if (countryOptions?.length) {
        return countryOptions?.find(({ value }) => value === countryCode)
      }
    },
    [countryOptions]
  ) */

  const ProductVariationAction = useCallback(() => {
    const gridClassName =
      productVariationRadioOptions?.length > 3 ? "donation-grid-four" : ""

    const el =
      typeof window !== "undefined" && document.getElementsByTagName("body")[0]
    if (el) el.style.paddingBottom = 0

    return (
      <div
        style={{
          background: theme?.colors?.whiteSmoke,
          borderRadius: "0.5rem",
          padding: isWide ? "2.5rem" : isDesktop ? "1.25rem" : "1rem",
        }}>
        <>
          {productVariationRadioOptions.length > 0 && (
            <StyledProductVariationOptions className={gridClassName}>
              {productVariationRadioOptions.length > 1 && (
                <RadioSelect
                  items={productVariationRadioOptions}
                  defaultValue={productVariationSelectValue}
                  onChange={(e) => {
                    const selectedValue = e.target.value
                    if (selectedValue?.isPassive !== true) {
                      setProductVariationSelectValue(selectedValue)
                      const filteredData = productVariationRadioOptions.filter(
                        (i) => i.id == selectedValue
                      )
                      if (filteredData.length > 0) {
                        setSelectedProductVariation(filteredData[0])
                      }
                    }
                  }}
                />
              )}
              {statsComponent}
              <StyledSupportButton
                color="carrot"
                data-testid="support-service"
                onClick={handleSupportClick}
                disabled={selectedProductVariation?.isSoldOut}>
                {selectedProductVariation?.isSoldOut ? (
                  <FormattedMessage
                    id="event::ticket::soldOut"
                    defaultMessage="Sold Out"
                  />
                ) : (
                  supportButtonText
                )}
              </StyledSupportButton>
            </StyledProductVariationOptions>
          )}
        </>
      </div>
    )
  }, [
    offer,
    productVariationRadioOptions,
    productVariationSelectValue,
    locale,
    isTablet,
  ])

  const ServiceTabs = useCallback(() => {
    return (
      <StyledWrapper data-testid="service-detail-overview">
        <StyledContent>
          <StyledSectionTitle
            className="caption"
            data-testid="service-detail-overview-tab">
            <FormattedMessage
              id="serviceDetail::about"
              defaultMessage="About"
            />
          </StyledSectionTitle>
          <StyledDescription
            dangerouslySetInnerHTML={{ __html: offer?.description }}
          />
        </StyledContent>
      </StyledWrapper>
    )
  }, [offer?.description])

  const ServiceContactTabs = useCallback(() => {
    return (
      <>
        <StyledContactTitle>
          <FormattedMessage
            id="service::detail::contacts::title"
            defaultMessage="Contacts"
          />
        </StyledContactTitle>
        <div>
          <StyledPhoneBox>
            {offer?.store?.phoneNumber && <FontAwesomeIcon icon={faPhone} />}
            <span>{getFormatPhoneNumber(offer?.store?.phoneNumber)}</span>
          </StyledPhoneBox>
        </div>
        {offer?.addressLine1 || offer?.store?.defaultOffer?.addressLine1 ? (
          <StyledLocationBox>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <div>
              <span>
                <p>
                  {[
                    // getCountryLabel(offer?.store?.country)?.label,
                    // offer?.store?.city,
                    offer?.addressLine1 ||
                      offer?.store?.defaultOffer?.addressLine1,
                    // offer?.store?.postCode,
                  ]
                    .filter((item) => item)
                    .join(", ")}
                </p>
              </span>
            </div>
          </StyledLocationBox>
        ) : null}
      </>
    )
  }, [offer?.store?.id])

  const ServiceStoreInfoTabs = useCallback(() => {
    return (
      <StyledStoreLogoContainer
        onClick={() => {
          navigate("/offers/store/" + offer?.store?.slug)
        }}>
        <StyledStoreLogo
          data-testid="service-detail-store-logo"
          src={getValueForLocale(offer?.store, "logo", locale, defaultLocale)}
        />
        <StyledStoreName>{offer?.store?.displayName}</StyledStoreName>
      </StyledStoreLogoContainer>
    )
  }, [offer?.store?.id])

  const toggleMapVisibility = () => {
    setIsMapVisible((prevIsMapVisible) => !prevIsMapVisible)
  }

  const locationForMap = offer?.addressLine1
    ? `${offer.addressLine1}, ${offer.city}, ${offer.postCode}, ${offer.country}`
    : offer?.store?.defaultOffer?.addressLine1
    ? `${offer.store.defaultOffer.addressLine1}, ${offer.store.defaultOffer.city}, ${offer.store.defaultOffer.postcode}, ${offer.store.defaultOffer.country}`
    : null

  const ServiceMapTabs = () => {
    return locationForMap ? (
      <StyledMapMainContainer className="service-map">
        <StyledMapContainer>
          <StyledMapGetDirections>
            <Button
              data-testid="btn-get-directions"
              variant="text"
              type="button"
              size="small"
              onClick={() => {
                generateGoogleMapsLinkAndOpen(locationForMap, "_blank")
              }}>
              <FormattedMessage
                id="service::detail::map::getDirections"
                defaultMessage="Get Directions"
              />
            </Button>
          </StyledMapGetDirections>
          <StyledMapHideShow>
            <Button
              data-testid="btn-map-hide-show"
              variant="text"
              type="button"
              size="small"
              align="right"
              onClick={toggleMapVisibility}>
              <FormattedMessage
                id={`service::detail::map::${isMapVisible ? "hide" : "show"}`}
                defaultMessage={isMapVisible ? "Hide map" : "Show on map"}
              />
              <FontAwesomeIcon
                icon={isMapVisible ? faChevronUp : faChevronDown}
              />
            </Button>
          </StyledMapHideShow>
        </StyledMapContainer>
        {isMapVisible && (
          <iframe
            width="100%"
            height={
              isWide ? "624px" : isDesktop || isTablet ? "341px" : "210px"
            }
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={generateGoogleMapsEmbedLink(locationForMap, locale)}></iframe>
        )}
      </StyledMapMainContainer>
    ) : null
  }
  const EventMapTabs = () => {
    return locationForMap ? (
      <StyledContent2>
        <StyledSectionTitle
          className="caption"
          data-testid="service-detail-overview-tab">
          <FormattedMessage
            id="serviceDetail::eventAddress"
            defaultMessage="Event Address"
          />
        </StyledSectionTitle>
        <StyledMapContainer>
          <StyledMapGetDirections>
            {offer?.addressLine1 || offer?.store?.defaultOffer?.addressLine1 ? (
              <StyledLocationBox>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <div>
                  <span>
                    <p>
                      {[
                        // getCountryLabel(offer?.store?.country)?.label,
                        // offer?.store?.city,
                        offer?.addressLine1 ||
                          offer?.store?.defaultOffer?.addressLine1,
                        // offer?.store?.postCode,
                      ]
                        .filter((item) => item)
                        .join(", ")}
                    </p>
                  </span>
                </div>
              </StyledLocationBox>
            ) : null}
          </StyledMapGetDirections>
          <StyledMapHideShow>
            <Button
              data-testid="btn-map-hide-show"
              variant="text"
              type="button"
              size="small"
              align="right"
              onClick={toggleMapVisibility}>
              <FormattedMessage
                id={`service::detail::map::${isMapVisible ? "hide" : "show"}`}
                defaultMessage={isMapVisible ? "Hide map" : "Show on map"}
              />
              <FontAwesomeIcon
                icon={isMapVisible ? faChevronUp : faChevronDown}
              />
            </Button>
          </StyledMapHideShow>
        </StyledMapContainer>

        {isMapVisible && (
          <iframe
            width="100%"
            height={
              isWide ? "624px" : isDesktop || isTablet ? "341px" : "210px"
            }
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={generateGoogleMapsEmbedLink(locationForMap, locale)}></iframe>
        )}
        <hr />
      </StyledContent2>
    ) : null
  }

  const descriptionToShare = offer?.shortDescription || offer?.description

  const statsComponent = (
    <>
      <OfferStats
        showSetPrice={selectedProductVariation?.allowCustomAmount}
        price={selectedProductVariation?.price}
        maxPacDiscount={
          selectedProductVariation?.pacDiscount ?? offer?.maxPacDiscount
        }
      />
    </>
  )

  const handleViewAll = () => {
    navigate(
      `/offers/?merchantType=0&offer=true&store=false&stores=${offer?.store?.id}`
    )
  }

  const EventLocationInfo =
    offer?.virtualEventLink || offer?.eventMapLink ? (
      <>
        <Spacer top={1.5} />
        <StyledEventLocationInfo data-testid="event-location-info">
          <LocationViewer
            address={{
              virtualEventLink: offer.virtualEventLink,
              locationLink: offer?.eventMapLink,
            }}
            isOffer
          />
        </StyledEventLocationInfo>
        <Spacer top={2} />
      </>
    ) : null

  const voucherUserBeforeContent = (
    <>
      {!offer?.isEventOffer && offer?.isVoucher && offer?.endDate && (
        <div className="use-before">
          <Spacer top={1} />
          <UseBefore isVoucher={offer?.isVoucher} endDate={offer?.endDate} />
          <Spacer top={1} />
        </div>
      )}

      {offer?.isReservationRequired && (
        <div className="use-before">
          <StyledExpiryDate>
            <StyledUseBefore>
              <FormattedMessage
                id="purchase::success::reservationRequired"
                defaultMessage="Reservation Required"
              />
            </StyledUseBefore>
          </StyledExpiryDate>
          <Spacer top={1} />
        </div>
      )}
    </>
  )

  const customNotFoundMessage = (
    <h6>
      <FormattedMessage
        id="serviceDetail::noOfferMessage::title"
        defaultMessage="The store does not provide any services at the moment."
      />
    </h6>
  )

  return (
    <>
      <SEO
        lang={locale}
        title={offer?.name}
        description={descriptionToShare}
        image={offerImage}
        pathname={pathname}
      />
      {loading ? (
        <Spinner />
      ) : offer ? (
        <>
          {!isTablet ? (
            <>
              {isNewService ? (
                // mobile version new service
                <StyledCongratsMobile>
                  <div className="modal-content">
                    <span>
                      <FormattedMessage
                        id="campaign::detail::congrats::heading"
                        defaultMessage="Congrats!"
                        tagName="h2"
                      />
                      <FormattedMessage
                        id="service::detail::congrats::heading-mobile"
                        defaultMessage="Your service is ready"
                        tagName="h2"
                      />
                    </span>
                    <FormattedMessage
                      id="service::detail::congrats::paragraph"
                      defaultMessage="Share the service on your socials with friends"
                      tagName="p"
                    />
                    <ShareComponent
                      offerId={offer?.id}
                      slug={slug}
                      isNewCampaign={isNewService}
                      name={offer?.name}
                      discount={offer?.store?.maxPacDiscount}
                      storeLogo={offer?.store?.logo}
                      position="center"
                    />
                  </div>
                  <div className="sticky-bottom-bar">
                    <hr />
                    <Button
                      type="button"
                      onClick={() => {
                        handleModalOpenClose()
                      }}>
                      <FormattedMessage
                        id="campaign::detail::congrats::shareButton"
                        defaultMessage="ok, let‘s share it"
                      />
                    </Button>
                  </div>
                </StyledCongratsMobile>
              ) : (
                // mobile version non-new service
                <StyledPageWrapper>
                  <DetailPageMobileContainer>
                    <MediaSlider
                      images={offerImages}
                      altName={`offer-detail-${offerImages}`}
                    />
                    {headerComponent}
                    {voucherUserBeforeContent}
                    <Spacer top={1.5} />
                    {offer?.isEventOffer ? EventLocationInfo : null}
                    <Spacer top={1.5} />
                    <ProductVariationAction />
                    <ServiceStoreInfoTabs />
                    {offer?.isEventOffer ? <EventMapTabs /> : null}
                    <ServiceTabs />
                    <hr style={{ margin: "0 1rem", width: "unset" }} />
                    {offer?.isEventOffer ? null : offer?.showAddressAndPhone ? (
                      <>
                        <ServiceContactTabs />
                        <ServiceMapTabs />
                      </>
                    ) : null}
                    <ServiceListing
                      storeId={offer?.store?.id}
                      handleViewAll={handleViewAll}
                      hideDefaultOffer={true}
                      servicesToExclude={[offer?.id]}
                    />
                  </DetailPageMobileContainer>
                </StyledPageWrapper>
              )}
            </>
          ) : (
            // non-mobile version
            <StyledPageWrapper>
              <DetailPageContainer>
                <MainPart>
                  <MediaSlider
                    images={offerImages}
                    altName={`offer-detail-${offerImages}`}
                    video={offer?.video}
                  />
                  {offer?.isEventOffer ? <EventMapTabs /> : null}
                  <ServiceTabs />
                  <hr />
                  {offer?.isEventOffer ? null : offer?.showAddressAndPhone ? (
                    <>
                      <ServiceContactTabs />
                      <ServiceMapTabs />
                    </>
                  ) : null}
                </MainPart>
                <SidePart
                  style={{
                    paddingTop: "unset",
                    backgroundColor: theme.colors.white,
                  }}>
                  <StyledHeaderAndShare>
                    <div>{headerComponent}</div>
                    <div>
                      <ShareComponent
                        offerId={offer?.id}
                        slug={slug}
                        isNewCampaign={isNewService}
                        name={offer?.name}
                        discount={offer?.store?.maxPacDiscount}
                        storeLogo={offer?.store?.logo}
                        position="center"
                      />
                    </div>
                  </StyledHeaderAndShare>
                  {voucherUserBeforeContent}
                  <Spacer top={1.5} />
                  {offer?.isEventOffer ? EventLocationInfo : null}
                  <Spacer top={1.5} />
                  <ProductVariationAction />
                  <ServiceStoreInfoTabs />
                  {isNewService ? (
                    <StyledCongratsPopup>
                      <div className="modal-content">
                        <FormattedMessage
                          id="campaign::detail::congrats::heading"
                          defaultMessage="Congrats!"
                          tagName="h2"
                        />
                        <FormattedMessage
                          id="service::detail::congrats::paragraph"
                          defaultMessage="Share the service on your socials with friends"
                          tagName="p"
                        />
                      </div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={handleModalOpenClose}
                      />
                    </StyledCongratsPopup>
                  ) : null}
                </SidePart>
              </DetailPageContainer>
              <ServiceListing
                storeId={offer?.store?.id}
                handleViewAll={handleViewAll}
                hideDefaultOffer={true}
                servicesToExclude={[offer?.id]}
                customNotFoundMessage={customNotFoundMessage}
              />
            </StyledPageWrapper>
          )}
        </>
      ) : (
        <ContentNotFound slug={slug} />
      )}
    </>
  )
}

ServiceDetail.propTypes = {
  slug: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}

export default ServiceDetail
