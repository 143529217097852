import { REQUIRED_FIELD_SYMBOL } from "@tmu/apollo/constants"
import { PhoneInput, TextInput, RadioSelect } from "@tmu/components/common"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useFormikContext } from "formik"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {
  StyledSingleDonation,
  StyledEmailLabel,
  StyledFormRow,
  StyledFlexFormRow,
} from "./index.styles"
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "react-tooltip"
import { yesNoType } from "@tmu/apollo/constants"
const IndividualCompanyForm = ({
  isCompany,
  isPhoneRequired,
  regularSignup,
}) => {
  const { formatMessage } = useIntl()
  const emailInformationText = formatMessage({
    id: "donation::emailInformationText",
    defaultMessage: "We will send the donation receipt to this address",
  })
  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
  } = useFormikContext()

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  return (
    <StyledSingleDonation>
      <TextInput style={{ display: "none" }} newDesign />
      <TextInput
        newDesign
        data-testid="input-firstName"
        id="firstName"
        name="firstName"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values?.firstName || ""}
        label={`${formatMessage({
          id: !isCompany ? "signUp::firstName" : "signUp::companyName",
          defaultMessage: !isCompany ? "First Name" : "Company Name",
        })}${REQUIRED_FIELD_SYMBOL}`}
        placeholder={formatMessage({
          id: !isCompany ? "signUp::firstName" : "signUp::companyName",
          defaultMessage: !isCompany ? "First Name" : "Company Name",
        })}
        error={errors.firstName}
        touched={touched.firstName}
      />
      <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
      {!isCompany && (
        <>
          <TextInput
            newDesign
            data-testid="input-lastName"
            id="lastName"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.lastName || ""}
            label={`${formatMessage({
              id: "signUp::lastName",
              defaultMessage: "Last Name",
            })}${REQUIRED_FIELD_SYMBOL}`}
            placeholder={formatMessage({
              id: "signUp::lastName",
              defaultMessage: "Last Name",
            })}
            error={errors.lastName}
            touched={touched.lastName}
          />
          <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
        </>
      )}
      {!regularSignup && !values.wantsRegister ? (
        <>
          <TextInput
            newDesign
            className="mt24"
            data-testid="input-email"
            id="intendedEmail"
            name="intendedEmail"
            type="email"
            onChange={(e) => {
              const lowerCaseEmail = e.target.value.toLowerCase()
              handleChange({
                target: {
                  name: e.target.name,
                  value: lowerCaseEmail,
                },
              })
            }}
            onBlur={handleBlur}
            value={values?.intendedEmail}
            label={
              <StyledEmailLabel>
                {formatMessage({
                  id: "signUp::email",
                  defaultMessage: "Email",
                })}
                {REQUIRED_FIELD_SYMBOL}
                <div
                  data-tooltip-id="emailTooltip"
                  data-tooltip-content={emailInformationText}
                  data-tooltip-place="bottom">
                  <FontAwesomeIcon
                    className="icon info-icon"
                    icon={faCircleInfo}
                  />
                </div>
                <Tooltip id="emailTooltip" className="tooltip" />
              </StyledEmailLabel>
            }
            placeholder={formatMessage({
              id: "signUp::emailPlaceholder",
              defaultMessage: "Your personal email address",
            })}
            error={errors.intendedEmail}
            touched={touched.intendedEmail}
          />
          <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
        </>
      ) : null}

      {/* {!regularSignup ? (
        <>
          {" "}
          <StyledFormRow className="title mt32">
            <FormattedMessage
              id="campaign::donationForm::taxDeductableReceipt"
              defaultMessage="Do you need a tax deductable receipt?"
            />
          </StyledFormRow>
          <StyledFlexFormRow>
            <RadioSelect
              items={yesNoType(formatMessage)}
              defaultValue={values?.isNeedReceipt === "true"}
              onChange={(e) => {
                setFieldValue("isNeedReceipt", e.target.value)
              }}
            />
          </StyledFlexFormRow>
        </>
      ) : null} */}
      {regularSignup || values?.isNeedReceipt === "true" ? (
        <>
          <TextInput
            newDesign
            data-testid="input-taxId"
            id="taxId"
            name="taxId"
            type="taxId"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.taxId || ""}
            label={`${formatMessage({
              id: !isCompany ? "signUp::taxId" : "signUp::vat",
              defaultMessage: !isCompany ? "Fiscal Code" : "VAT",
            })}`}
            placeholder={formatMessage({
              id: !isCompany ? "signUp::fiscalCodePlaceHolder" : "signUp::vat",
              defaultMessage: !isCompany ? "Fiscal Code" : "VAT",
            })}
            error={errors.taxId}
            touched={touched.taxId}
          />
          <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
          <PhoneInput
            title={`${formatMessage({
              id: "dashboard::profile::phone",
              defaultMessage: "phone number",
            })}${isPhoneRequired ? REQUIRED_FIELD_SYMBOL : ""}`}
            name="phoneNumber"
            isRequired={isPhoneRequired}
            value={values?.phoneNumber || ""}
            errors={errors}
            touched={touched}
            onChange={(phone) => setFieldValue("phoneNumber", phone, true)}
            onBlur={() => setTouched({ ...touched, phoneNumber: true })}
          />
          <Spacer bottom={isWide ? 2.5 : isTablet || isDesktop ? 2 : 1.5} />
        </>
      ) : null}
    </StyledSingleDonation>
  )
}

export default IndividualCompanyForm
