import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledWrapper = styled.div`
  margin: 4rem auto;
  padding: 0 1rem;
  /* .checkmark {
    background-color: ${(p) => p.theme.colors.carrot} !important;
    border: 1px solid ${(p) => p.theme.colors.carrot} !important;
  } */

  /* .checkmark:after {
    background-color: ${(p) => p.theme.colors.carrot} !important;
  } */
  .terms-label {
    padding-left: 2.5rem;
    font-size: 1rem;
    a {
      font-size: 1rem;
    }
  }
  .radio-select-item label {
    margin-bottom: 0.5rem;
    width: auto;
    padding-left: 2.5rem;
  }
  .type-radio {
    display: flex;
    align-items: center;
    > div:first-of-type {
      margin-right: 6.5rem;
    }
    label {
      margin: 0;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: block;
      > div {
        label {
          margin: 0;
        }
        &:last-of-type {
          label {
            margin-top: 1rem;
          }
        }
      }
    }
  }

  .service-form > fieldset {
    min-width: 0;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      display: block;
    }

    > div {
      margin-bottom: 0.75rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
        margin-bottom: 1.5rem;
      }
    }

    .hide-on-mobile {
      display: none;
    }
    .hide-on-desktop {
      display: block;
    }
    .full-width {
      width: 100%;
      grid-column: 1 / 3;
    }
    .subheader {
      margin-top: 2rem;
      margin-bottom: 0.75rem;
    }
    .react-datepicker-wrapper {
      width: 15rem;
    }

    hr,
    .second-grid {
      grid-column: 1 / span 2;
      margin-top: 0.5rem;
    }
    hr {
      margin: 2rem 0;
    }
    .mt-none {
      margin-top: 2rem;
    }

    .mt-small {
      margin-top: 0.5rem;
    }
    .mt-large {
      margin-top: 1rem;
    }
    .input-feedback {
      margin: 0.5rem 0 0;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .field-error {
      height: 0.9rem;
    }

    .react-select__value-container {
      > div {
        width: auto !important;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: auto;
    max-width: ${(p) => p.theme.breakpoints.desktop};

    .service-form > fieldset {
      display: grid;
      grid-template-columns: 15.0625rem auto;
      grid-gap: 2.5rem 1.9375rem;

      > div {
        margin-bottom: 0;
      }

      .hide-on-mobile {
        display: block;
      }
      .hide-on-desktop {
        display: none;
      }
      .subheader {
        margin-top: 0;
        margin-bottom: 0;
      }

      .second-grid {
        margin-top: 0rem;
        grid-column: 1 / span 2;
      }
      hr {
        margin: 0;
      }
      .mt-none {
        margin-top: 0;
      }

      .mt-large {
        margin-top: 2rem;
      }
    }
  }

  h1 {
    margin-bottom: 1.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin-bottom: 1.875rem;
    }
  }
`

export const StyledTitle = styled.h1`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  text-align: center;
  color: ${(p) => p.theme.colors.black};
  font-size: 1.5rem;
  line-height: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
`

export const StyledLabel = styled.p`
  width: auto;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  padding: 0;
  align-self: auto;

  &.maps {
    color: ${(p) => p.theme.colors.carrot}!important;
    cursor: pointer;
  }
  &.top-align {
    align-self: flex-start;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-size: 1.25rem;
    line-height: 1.75rem;

    span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.liteBlack};
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: 0.0187rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.bold};
      letter-spacing: unset;
      margin-top: 0.6875rem;
    }
  }

  &.subtext {
    margin-top: 0.5rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.black};
  }

  .info {
    margin-top: 0;
    font-size: 0.5rem;

    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    line-height: 1.25rem;
  }

  :not(.subheader):not(.subtext) {
    font-weight: ${(p) => p.theme.fontWeights.black};
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.disabled};
    margin-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-self: center;

    .info {
      margin-top: 0.75rem;
      font-size: 0.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: ${(p) => p.theme.colors.black};
    margin: 0;
    :not(.subheader):not(.subtext) {
      font-weight: ${(p) => p.theme.fontWeights.bold};
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: ${(p) => p.theme.colors.black};
      margin: 0;
      text-transform: none;
    }
  }
`

export const StyledLabelHref = styled.a`
  width: auto;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  padding: 0;
  align-self: center;

  &.maps {
    cursor: pointer;
  }
  &.top-align {
    align-self: flex-start;

    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-size: 1.25rem;
    line-height: 1.75rem;

    span {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.liteBlack};
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: 0.0187rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-family: ${(p) => p.theme.fontFamilies.headline};
      font-weight: ${(p) => p.theme.fontWeights.bold};
      letter-spacing: unset;
      margin-top: 0.6875rem;
    }
  }

  &.subtext {
    margin-top: 0.5rem;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${(p) => p.theme.colors.black};
  }

  .info {
    margin-top: 0;
    font-size: 0.5rem;

    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    line-height: 1.25rem;
  }

  :not(.subheader):not(.subtext) {
    font-weight: ${(p) => p.theme.fontWeights.black};
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    color: ${(p) => p.theme.colors.disabled};
    margin-top: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    .info {
      margin-top: 0.75rem;
      font-size: 0.75rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: ${(p) => p.theme.colors.black};
    margin: 0;
    :not(.subheader):not(.subtext) {
      font-weight: ${(p) => p.theme.fontWeights.bold};
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: ${(p) => p.theme.colors.black};
      margin: 0;
      text-transform: none;
    }
  }
`

export const StyledSupportCharityContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-height: 4rem;
  }
`
export const StyledSupportCharityName = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-size: 1rem;
  line-height: 1.375rem;
  align-self: center;
  margin-left: 0.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 18rem;
`
export const StyledSupportCharitySelectButton = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pacificBlue};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 0;
  margin-left: auto;
  align-self: center;
  cursor: pointer;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`
export const StyledSupportCharitySelectButtonMobile = styled.div`
  &,
  button {
    width: 100%;
  }
`

export const StyledSupportCharityLogo = styled.div``

export const StyledFlexRow = styled.div`
  display: block;
  /* .checkmark {
    background-color: ${(p) => p.theme.colors.carrot} !important;
    border: 1px solid ${(p) => p.theme.colors.carrot} !important;
  }

  .checkmark:after {
    background-color: ${(p) => p.theme.colors.carrot} !important;
  } */
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
  }
`
export const StyledInlineRow2 = styled.div`
  display: block;

  /* .checkmark {
    background-color: ${(p) => p.theme.colors.carrot} !important;
    border: 1px solid ${(p) => p.theme.colors.carrot} !important;
  }

  .checkmark:after {
    background-color: ${(p) => p.theme.colors.carrot} !important;
  } */
  .mt-16 {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  .amount {
    width: 8rem;
    margin-bottom: 2rem;
  }
  label {
    margin-bottom: 1rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: space-between;
    .mt-16 {
      margin-bottom: 0;
    }
    .amount {
      margin-bottom: 0;
    }
  }
`
export const StyledFlexRowMod = styled(StyledFlexRow)`
  input {
    width: 6.5rem;
  }
  p {
    text-align: right;
    margin-right: 1rem;
    text-align: left;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      text-align: right;
    }
  }
`

export const StyledInlineRow = styled.div`
  display: grid;
  grid-template-columns: 8rem auto;
  grid-gap: 1.6875rem 0;
  /* .checkmark {
    background-color: ${(p) => p.theme.colors.carrot} !important;
    border: 1px solid ${(p) => p.theme.colors.carrot} !important;
  }

  .checkmark:after {
    background-color: ${(p) => p.theme.colors.carrot} !important;
  } */
  .input-wrapper {
    display: block;
  }

  > div:nth-child(2) {
    justify-self: right;
    grid-template-columns: auto 9rem;
    .input-wrapper {
      margin: 0;
    }

    input {
      font-size: 0.9rem;
      padding: 0 0.3125rem 0 0.75rem;
    }
  }

  > div:nth-child(3) {
    grid-column: 1 / 3;
  }

  .btn-delete-entry {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: right;
    color: ${(p) => p.theme.colors.carrot};
    width: fit-content;
    margin-top: 0.5rem;
    grid-column: 1 / span 2;
    justify-self: right;
    padding: 0;

    svg {
      width: 0.875rem;
      height: 1.125rem;
      margin-left: 0.375rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      // grid-column: 2;
      margin-top: 0;
    }
  }

  .currency-icon input {
    padding-left: 1.5rem;
  }
`

export const StyledActionButton = styled(Button)`
  min-width: auto;

  height: 2.5rem;
  margin-bottom: 1rem;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};

  align-self: self-start;
  justify-self: right;
  .spinner-condensed div {
    box-shadow: 0 0.125rem 0 0 ${(p) => p.theme.colors.white};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    min-width: 12.5rem;
  }
`
export const StyledButtonContainer = styled.div`
  display: flex;
  margin-top: 1.75rem;
  justify-content: space-between;
  button {
    width: 10rem;
    margin-right: 1.5rem;
  }

  .full-width {
    width: 100%;
    grid-column: 1 / 3;
  }
`

export const StyledPacDiscount = styled.div`
  .discount-input {
    width: 5rem;
    input {
      padding-right: 0.8rem;
    }
  }
  .discount-input:after {
    content: "%";
    position: absolute;
    right: 0.6rem;
    top: 0.875rem;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    color: ${(p) => p.theme?.colors?.blue};
    font-size: 1rem;
    height: 1rem;
    margin: 0.5625rem 0 0 0.125rem;
    cursor: pointer;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      margin: 0.75rem 0 0 -0.6875rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.6669rem;
      height: 1.6669rem;
    }
  }
`

export const StyledModalContainer = styled.div`
  line-height: 2;

  p {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.375rem;

      margin: 1rem 0 1.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;

      margin: 1.5rem 0 3rem;
    }
  }

  .modal-leave {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.white};

    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    margin: 0 auto;
    width: 50%;
    height: 2.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1rem;
      height: 2.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.375rem;
      height: 3.375rem;
    }
  }
`

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-align: center;
    line-height: 1.5;
    
    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: left;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 2rem;
      line-height: 2.625rem;
    }
  }
`

export const StyledContactForm = styled.div`
  margin-top: 2rem;
  &.mt-0 {
    margin-top: 0;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
`

export const StyledField = styled.div`
  margin-bottom: 0.5rem;
  .caption {
    margin-bottom: 0.5rem;
  }
  &.country {
    width: 21.5rem;
  }
  &.postal {
    width: 11.5rem;
  }
`

export const StyledContactSection = styled.div`
  .btn-change-address {
    padding: 0;
    margin: 0;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${(p) => p.theme.colors.carrot};
  }
`

export const StyledPhoneAndAddress = styled.div`
  display: grid;
  grid-template-columns: 1.5rem auto;
  gap: 1rem 0.5rem;
  margin: 1.5rem 0;
  svg {
    color: ${(p) => p.theme.colors.carrot};
    font-size: 1rem;
    align-self: center;
    justify-self: center;
  }
  p {
    margin: 0;
  }
`

export const StyledAddressLinkRow = styled.div`
  .checkmark {
    background-color: ${(p) => p.theme.colors.carrot} !important;
    border: 1px solid ${(p) => p.theme.colors.carrot} !important;
  }

  .checkmark:after {
    background-color: ${(p) => p.theme.colors.carrot} !important;
  }
  position: relative;
  .input-wrapper {
    margin-top: 0 !important;
  }
  input {
    padding-right: 2rem;
    text-overflow: ellipsis;
    font-size: 0.875rem;
  }
  svg {
    position: absolute;
    top: 0.9rem;
    right: 0.5rem;
    color: ${(p) => p.theme.colors[p.error ? "red" : "blue"]};
    font-size: 1.25rem;
    height: 1.25rem;
    font-weight: ${(p) => p.theme.fontWeights.bold};
    cursor: pointer;
  }
  &:focus-within svg,
  &:hover svg {
    color: ${(p) => p.theme.colors.blue};
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    input {
      font-size: 1rem;
    }
  }
`
export const StyledExplanationText = styled.p`
  position: absolute;
  display: ${(p) => (p.isUnlisted ? "block" : "none")};
  top: 3rem;
  right: 0;
  width: 100%;
  max-width: 23.5rem;
  z-index: 2;
  margin: 0;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0 0.25rem 0.75rem ${(p) => p.theme.colors.primaryTextColorLight};
  border-radius: 0.25rem;
  padding: 1rem;
`

export const StyledInvoicingWrapper = styled.div`
  display: flex;
  gap: 1rem;
`
export const StyledFavCharityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    max-height: 6.25rem;
    max-width: 6.25rem;
  }
  .change {
    color: ${(p) => p.theme.colors["blue"]};
    font-size: 0.75rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`
