import React, { useEffect, useCallback, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useAuth, useCountries, useFooterType } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import { getFormatPhoneNumber } from "@tmu/utils/formatPhoneNumber"
import {
  generateGoogleMapsLinkAndOpen,
  generateGoogleMapsEmbedLink,
} from "@tmu/utils/googleMaps"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  LazyImage,
  Money,
  ShareComponent,
} from "@tmu/components/common"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { faShareAlt as faShare } from "@fortawesome/pro-regular-svg-icons/faShareAlt"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp"
import { FOOTER_TYPE, OFFER_TYPE } from "@tmu/apollo/constants"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledSDIMainContainer,
  StyledSDILeftContainer,
  StyledLeftBackStores,
  StyledLeftLogoBox,
  StyledLeftCategoryBox,
  StyledLeftCategory,
  StyledLeftContacts,
  StyledLeftPhoneBox,
  StyledLeftLocationBox,
  StyledSDICenterContainer,
  StyledCenterTitleContainer,
  StyledCenterTitle,
  StyledCenterImage,
  StyledCenterContent,
  StyledSDIRightContainer,
  StyledRightPacBox,
  StyledRightDiscountBox,
  StyledSDIRightCorporateContainer,
  StyledRightCorporate,
  StyledSDIMobileMainContainer,
  StyledMobileTitleLogoBox,
  StyledMobileTitle,
  StyledMobileContacts,
  StyledMobileLocationBox,
  StyledMobileCategoryBoxContainer,
  StyledMobileCategoryBox,
  StyledMobileCategory,
  StyledMobileContent,
  StyledMobilePacBox,
  StyledMobileDiscountBox,
  StyledMobileHr,
  StyledMobileCorporateContainer,
  StyledMobileCorporate,
  StyledFundraisingPortfolioBox,
  StyledPayWithPacs,
} from "./index.styles"
import {
  StyledMapMainContainer,
  StyledMapGetDirections,
  StyledMapHideShow,
} from "@tmu/components/services/ServiceDetail/index.styles"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"
import { useOfferLink } from "@tmu/src/hooks"

const { CLOUDFLARE_IMAGE_URL } = process.env

const StoreDetailInfo = ({ slug, location, merchant }) => {
  const { isAuthenticated } = useAuth()
  const { countryOptions, getCountries } = useCountries()
  const { locale, defaultLocale, formatMessage } = useIntl()
  const [isMapVisible, setIsMapVisible] = useState(false)
  const { getOfferLink } = useOfferLink()

  const isMerchantWillBeDisplayed = merchant?.isDisplayed
  const defaultOffer = merchant?.defaultOffer
  const isMerchantOffline = defaultOffer?.offerType === OFFER_TYPE.OFFLINE
  const isOfferInternal = defaultOffer?.offerType === OFFER_TYPE.INTERNAL
  const isCorporate = defaultOffer?.offerType === OFFER_TYPE.BRAND
  if (isCorporate && !isMerchantWillBeDisplayed) {
    navigate(`/offers/?offer=true&store=true`)
    return
  }
  useEffect(() => {
    !countryOptions && getCountries()
  })

  const getCountryLabel = useCallback(
    (countryCode) => {
      if (countryOptions?.length) {
        return countryOptions?.find(({ value }) => value === countryCode)
      }
    },
    [countryOptions]
  )

  const handleExternalOffer = () => {
    if (isOfferInternal) {
      navigate(
        `/offers/?merchantType=0&offer=true&store=true&stores=${merchant?.id}`
      )
    } else if (!isMerchantOffline) {
      if (!isAuthenticated) {
        const redirectUrl = `/${locale}/offers/store/${slug}?offer=true`
        navigate(`/signin?&lang=${locale}&next=${redirectUrl}`)
      } else {
        getOfferLink({
          variables: { offerId: defaultOffer?.id },
          onCompleted: (offerData) => {
            if (offerData?.offerLinkWithToken?.offerLink) {
              window.open(offerData.offerLinkWithToken.offerLink, "_blank")
            }
          },
        })
      }
    } else {
      const address = `${defaultOffer?.addressLine1}, ${defaultOffer?.city}, ${defaultOffer?.postCode}, ${defaultOffer?.country}`

      generateGoogleMapsLinkAndOpen(address, "_blank")
    }
  }


  const redirectToCategory = (slug) => {
    navigate("/offers/?offer=true&store=true&offerCategories=" + slug)
  }

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const logoSize = {
    width: isWide ? 361 : isDesktop ? 197 : isTablet ? 146 : 98,
  }

  const imageSize = {
    width: isWide ? 414 : isDesktop ? 228 : isTablet ? 168 : 360,
  }

  const pacSize = {
    width: isWide ? 31 : 23,
    height: isWide ? 26 : 20,
  }

  const charityFriendlySize = {
    width: isWide ? 80 : isDesktop ? 64 : isTablet ? 54 : 32,
    height: isWide ? 80 : isDesktop ? 64 : isTablet ? 54 : 32,
  }

  const offerCategories = useMemo(() => {
    if (defaultOffer) {
      const offerCategories = defaultOffer?.categories?.edges?.map(
        (category) => {
          return category.node
        }
      )
      return offerCategories
    }
  }, [defaultOffer])

  const name = getValueForLocale(merchant, "name", locale, defaultLocale)

  const shortDescription = getValueForLocale(
    merchant,
    "shortDescription",
    locale,
    defaultLocale
  )

  useFooterType({
    footerType: !isTablet ? FOOTER_TYPE.HIDE : FOOTER_TYPE.TIGHT,
  })

  const payWithPacsText = formatMessage({
    id: "storeCard::payWtihPacs",
    defaultMessage: "Pay with PACs or donate",
  })

  const payWithPacs = <StyledPayWithPacs>{payWithPacsText}</StyledPayWithPacs>

  const SDIMapTabs = useCallback(() => {
    return (
      <StyledMapMainContainer className="service-map">
        <StyledMapGetDirections
          style={{
            margin: isWide
              ? "0 0 1.5rem"
              : isDesktop || isTablet
                ? "0 0 1rem"
                : "1rem",
          }}>
          <Button
            data-testid="btn-get-directions"
            variant="text"
            type="button"
            size="small"
            onClick={() => {
              const address = `${defaultOffer?.addressLine1}, ${defaultOffer?.city}, ${defaultOffer?.postCode}, ${defaultOffer?.country}`

              generateGoogleMapsLinkAndOpen(address, "_blank")
            }}>
            <FormattedMessage
              id="service::detail::map::getDirections"
              defaultMessage="Get Directions"
            />
          </Button>
        </StyledMapGetDirections>
        <StyledMapHideShow>
          <Button
            data-testid={`btn-map-hide-show`}
            variant="text"
            type="button"
            size="small"
            align="right"
            onClick={toggleMapVisibility}
            style={{ margin: isTablet ? "unset" : "1rem" }}>
            <FormattedMessage
              id={`service::detail::map::${isMapVisible ? "hide" : "show"}`}
              defaultMessage={isMapVisible ? "Hide map" : "Show on map"}
            />
            <FontAwesomeIcon
              icon={isMapVisible ? faChevronUp : faChevronDown}
            />
          </Button>
        </StyledMapHideShow>
        {isMapVisible && (
          <iframe
            style={{
              border: 0,
              width: "100%",
              height: "14.5625rem",
            }}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={generateGoogleMapsEmbedLink(
              `${defaultOffer?.addressLine1}, ${defaultOffer?.city}, ${defaultOffer?.postCode}, ${defaultOffer?.country}`,
              locale
            )}></iframe>
        )}
      </StyledMapMainContainer>
    )
  }, [defaultOffer?.location, isMapVisible, isTablet, isDesktop, isWide])

  const toggleMapVisibility = () => {
    setIsMapVisible((prevIsMapVisible) => !prevIsMapVisible)
  }

  const el =
    typeof window !== "undefined" && document.getElementsByTagName("body")[0]
  if (el) el.style.paddingBottom = !isTablet ? "9.375rem" : 0

  return (
    <>
      {isTablet ? (
        <StyledSDIMainContainer>
          <StyledSDILeftContainer>
            <div>
              {!isCorporate ? (
                <StyledLeftBackStores
                  onClick={() => {
                    navigate(
                      `/offers/?externalStore=true&internalStore=true&merchantType=${isMerchantOffline ? 1 : 0
                      }&offer=false&store=true`
                    )
                  }}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <Button
                    variant="link"
                    text={
                      <FormattedMessage
                        id="storeDetail::info::linkButton"
                        defaultMessage="Back to the stores"
                      />
                    }
                  />
                </StyledLeftBackStores>
              ) : (
                <StyledLeftBackStores
                  onClick={() => {
                    navigate(`/corporate`)
                  }}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  <Button
                    variant="link"
                    text={
                      <FormattedMessage
                        id="storeDetail::info::linkButtonRedirect"
                        defaultMessage="Social Impact Organizations"
                      />
                    }
                  />
                </StyledLeftBackStores>
              )}
              <StyledLeftLogoBox>
                <LazyImage
                  src={merchant?.logo}
                  altName={`merchant-logo`}
                  {...logoSize}
                  height="auto"
                />
              </StyledLeftLogoBox>
              <StyledLeftCategoryBox>
                {offerCategories?.length
                  ? offerCategories.map((category) => {
                    return (
                      <StyledLeftCategory
                        onClick={() => redirectToCategory(category?.slug)}>
                        <span>
                          {getValueForLocale(
                            category,
                            "name",
                            locale,
                            defaultLocale
                          )}
                        </span>
                      </StyledLeftCategory>
                    )
                  })
                  : null}
              </StyledLeftCategoryBox>
            </div>
            <div
              style={{
                marginTop: offerCategories?.length
                  ? isWide
                    ? "1.5rem"
                    : "1rem"
                  : "",
              }}>
              <div>
                {isOfferInternal && (
                  <StyledLeftContacts>
                    {merchant?.website ? (
                      <a href={merchant?.website} target="_blank">
                        <FormattedMessage
                          id="storeCard::contacts::title"
                          defaultMessage="Official website"
                          tagName="span"
                        />
                      </a>
                    ) : null}
                  </StyledLeftContacts>
                )}
                <StyledLeftPhoneBox
                  className={isOfferInternal ? "contacts" : ""}>
                  {merchant?.phoneNumber && <FontAwesomeIcon icon={faPhone} />}
                  <span>{getFormatPhoneNumber(merchant?.phoneNumber)}</span>
                </StyledLeftPhoneBox>
              </div>
              {!!defaultOffer?.country && (
                <StyledLeftLocationBox>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  {!isMerchantOffline ? (
                    <span>{getCountryLabel(defaultOffer?.country)?.label}</span>
                  ) : (
                    <div>
                      <span>
                        <p>{defaultOffer?.addressLine1}</p>
                        <p>
                          {[
                            defaultOffer?.city,
                            defaultOffer?.postCode,
                            getCountryLabel(defaultOffer?.country)?.label,
                          ]
                            .filter((item) => item)
                            .join(", ")}
                        </p>
                      </span>
                    </div>
                  )}
                </StyledLeftLocationBox>
              )}
            </div>
            {isOfferInternal && defaultOffer?.location && <SDIMapTabs />}
          </StyledSDILeftContainer>
          <StyledSDICenterContainer>
            {isCorporate ? (
              <StyledCenterTitleContainer>
                <StyledCenterTitle>
                  {merchant?.displayName || name}
                </StyledCenterTitle>{" "}
                <StyledCenterImage>
                  <img
                    src={
                      CLOUDFLARE_IMAGE_URL +
                      "/static/assets/images/corporate/social_Impact.png"
                    }
                    alt={`store-detail-info-corporate`}
                    {...charityFriendlySize}
                  />
                </StyledCenterImage>
              </StyledCenterTitleContainer>
            ) : (
              <StyledCenterTitle>
                {merchant?.displayName || name}
              </StyledCenterTitle>
            )}
            <StyledCenterContent
              dangerouslySetInnerHTML={{
                __html: shortDescription ?? "",
              }}
            />
          </StyledSDICenterContainer>
          {isCorporate ? (
            <StyledSDIRightCorporateContainer>
              <div style={{ margin: isWide ? "0 0 2.1875rem" : "unset" }}>
                <ShareComponent
                  offerId={defaultOffer?.id}
                  url={location.href}
                  name={name}
                  slug={slug}
                  position="center"
                />
              </div>
              <StyledRightCorporate>
                <div>
                  <FormattedMessage
                    id="storeCard::corporate::title"
                    defaultMessage="Fundraising portfolio"
                    tagName="h4"
                  />
                </div>
                <div>
                  <span>
                    {merchant?.totalCampaignCount ??
                      merchant?.campaignCountForDisplay}
                  </span>
                  <FormattedMessage
                    id="storeCard::corporate::activeCampaigns"
                    defaultMessage="Active Campaigns"
                    tagName="p"
                  />
                </div>
                <div>
                  <span>
                    {merchant?.totalDonationCount ??
                      merchant?.donationCountForDisplay}
                  </span>
                  <FormattedMessage
                    id="storeCard::corporate::totalDonations"
                    defaultMessage="Total donations"
                    tagName="p"
                  />
                </div>
                <div>
                  <span>
                    <Money
                      value={
                        merchant?.totalFundedAmount ??
                        merchant?.fundedAmountForDisplay
                      }
                      currency={true}
                    />
                  </span>
                  <FormattedMessage
                    id="storeCard::corporate::totalRaised"
                    defaultMessage="Total raised"
                    tagName="p"
                  />
                </div>
                <div>
                  <span>
                    <Money value={merchant?.totalPacDiscountAmount} />
                  </span>
                  <FormattedMessage
                    id="storeCard::corporate::pacWalletDiscounts"
                    defaultMessage="Pac Wallet Discounts"
                    tagName="p"
                  />
                </div>
              </StyledRightCorporate>
            </StyledSDIRightCorporateContainer>
          ) : (
            <StyledSDIRightContainer>
              <div>
                <div className="share-wrapper">
                  <ShareComponent
                    url={location.href}
                    name={name}
                    slug={slug}
                    position="center"
                  />
                </div>
                <LazyImage
                  src={defaultOffer?.image}
                  altName={`defaultOffer-image`}
                  {...imageSize}
                  height="auto"
                />
              </div>
              <div
                className={
                  isOfferInternal || !isMerchantOffline ? "gray-back" : ""
                }>
                {Number(merchant?.maxPacDiscountInOffers) > 0 ? (
                  !isMerchantOffline ? (
                    <StyledRightPacBox>
                      <img
                        src={
                          CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"
                        }
                        alt={`store-detail-info-pacs`}
                        {...pacSize}
                      />
                      <span>
                        <FormattedMessage
                          id="storeCard::discount::upTo"
                          defaultMessage="Up to"
                        />{" "}
                        <Money
                          style="percent"
                          value={merchant?.maxPacDiscountInOffers}
                          currency={false}
                        />
                      </span>
                    </StyledRightPacBox>
                  ) : (
                    <StyledRightDiscountBox>
                      <span>
                        <FormattedMessage
                          id="storeCard::discount::discountUpTo"
                          defaultMessage="Donate up to {discount} discount"
                          values={{
                            discount: (
                              <Money
                                style="percent"
                                value={merchant?.maxPacDiscountInOffers}
                                currency={false}
                              />
                            ),
                          }}
                        />
                      </span>
                    </StyledRightDiscountBox>
                  )
                ) : null}
                {(isOfferInternal || !isMerchantOffline) &&
                  Number(merchant?.maxPacDiscountInOffers) > 0 && (
                    <>
                      {payWithPacs}
                      <Spacer bottom={isWide ? 2 : 0.75} />
                    </>
                  )}
                <Button
                  data-testid={`btn-go-to-store`}
                  type="button"
                  color="carrot"
                  className="btn-go-to-store"
                  onClick={() => handleExternalOffer()}>
                  <FormattedMessage
                    id={`storeDetail::info::${isOfferInternal
                      ? "servicesButton"
                      : !isMerchantOffline
                        ? "button"
                        : "directionsButton"
                      }`}
                    defaultMessage={
                      isOfferInternal
                        ? "See merchant's services"
                        : !isMerchantOffline
                          ? "Visit the store"
                          : "Get directions"
                    }
                  />
                </Button>
              </div>
            </StyledSDIRightContainer>
          )}
        </StyledSDIMainContainer>
      ) : (
        <StyledSDIMobileMainContainer isCorporate={isCorporate}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="back-to-stores"
            onClick={() => {
              navigate("/corporate")
            }}
          />
          <div className="default-offer-image">
            <LazyImage
              src={defaultOffer?.image}
              altName={`defaultOffer-image`}
              {...imageSize}
            />
          </div>
          <StyledMobileTitleLogoBox>
            <StyledMobileTitle>{name}</StyledMobileTitle>
            <LazyImage
              src={merchant?.logo}
              altName={`merchant-logo`}
              {...logoSize}
              fit="cover"
            />
          </StyledMobileTitleLogoBox>
          {isOfferInternal && (
            <StyledMobileContacts>
              {merchant?.website ? (
                <a href={merchant?.website} target="_blank">
                  <FormattedMessage
                    id="storeCard::contacts::title"
                    defaultMessage="Official website"
                    tagName="span"
                  />
                </a>
              ) : null}
            </StyledMobileContacts>
          )}
          <StyledMobileLocationBox>
            <div>
              {merchant?.phoneNumber && (
                <div>
                  <FontAwesomeIcon icon={faPhone} className="phone-icon" />
                  <span>{getFormatPhoneNumber(merchant?.phoneNumber)}</span>
                </div>
              )}
              <div style={{ display: "flex", marginTop: "0.625rem" }}>
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="location-icon"
                />
                {!isMerchantOffline ? (
                  <span>{getCountryLabel(defaultOffer?.country)?.label}</span>
                ) : (
                  <span>
                    <p>{defaultOffer?.addressLine1}</p>
                    <p>
                      {[
                        defaultOffer?.city,
                        defaultOffer?.postCode,
                        getCountryLabel(defaultOffer?.country)?.label,
                      ]
                        .filter((item) => item)
                        .join(", ")}
                    </p>
                  </span>
                )}
              </div>
            </div>
            <div>
              <ShareComponent
                className="mt-0"
                offerId={defaultOffer?.id}
                slug={slug}
                isNewCampaign={isNewService}
                name={defaultOffer?.name}
                discount={merchant?.maxPacDiscount}
                storeLogo={merchant?.logo}
                position="center"
              />
            </div>
          </StyledMobileLocationBox>
          {isCorporate ? (
            <StyledMobileCategoryBoxContainer>
              <StyledMobileCategoryBox>
                {offerCategories?.length
                  ? offerCategories.map((category) => {
                    return (
                      <StyledMobileCategory
                        onClick={() => redirectToCategory(category?.slug)}>
                        <span>
                          {getValueForLocale(
                            category,
                            "name",
                            locale,
                            defaultLocale
                          )}
                        </span>
                      </StyledMobileCategory>
                    )
                  })
                  : null}
              </StyledMobileCategoryBox>
              <img
                src={
                  CLOUDFLARE_IMAGE_URL +
                  "/static/assets/images/corporate/social_Impact.png"
                }
                alt={`store-detail-info-corporate`}
                {...charityFriendlySize}
              />
            </StyledMobileCategoryBoxContainer>
          ) : (
            <StyledMobileCategoryBox>
              {offerCategories?.length
                ? offerCategories.map((category) => {
                  return (
                    <StyledMobileCategory
                      onClick={() => redirectToCategory(category?.slug)}>
                      <span>
                        {getValueForLocale(
                          category,
                          "name",
                          locale,
                          defaultLocale
                        )}
                      </span>
                    </StyledMobileCategory>
                  )
                })
                : null}
            </StyledMobileCategoryBox>
          )}
          <StyledMobileContent>
            <StyledCenterContent
              dangerouslySetInnerHTML={{
                __html: shortDescription ?? "",
              }}
            />
          </StyledMobileContent>
          {isOfferInternal && defaultOffer?.location && <SDIMapTabs />}
          {isCorporate && (
            <>
              <StyledMobileHr />
              <StyledMobileCorporateContainer>
                <FormattedMessage
                  id="storeCard::corporate::title"
                  defaultMessage="Fundraising portfolio"
                  tagName="h4"
                />
                <StyledMobileCorporate>
                  <StyledFundraisingPortfolioBox>
                    <span>{merchant?.totalCampaignCount}</span>
                    <FormattedMessage
                      id="storeCard::corporate::campaigns"
                      defaultMessage="Campaigns"
                      tagName="p"
                    />
                  </StyledFundraisingPortfolioBox>
                  <StyledFundraisingPortfolioBox>
                    <span>{merchant?.totalDonationCount}</span>
                    <FormattedMessage
                      id="storeCard::corporate::totalDonations"
                      defaultMessage="Total donations"
                      tagName="p"
                    />
                  </StyledFundraisingPortfolioBox>
                  <StyledFundraisingPortfolioBox>
                    <span>
                      <Money
                        value={merchant?.totalFundedAmount}
                        currency={true}
                      />
                    </span>
                    <FormattedMessage
                      id="storeCard::corporate::totalRaised"
                      defaultMessage="Total raised"
                      tagName="p"
                    />
                  </StyledFundraisingPortfolioBox>
                  <StyledFundraisingPortfolioBox>
                    <span>
                      <Money
                        value={merchant?.totalPacDiscountAmount}
                        currency={true}
                      />
                    </span>
                    <FormattedMessage
                      id="storeCard::corporate::pacWalletDiscounts"
                      defaultMessage="Pac Wallet Discounts"
                      tagName="p"
                    />
                  </StyledFundraisingPortfolioBox>
                </StyledMobileCorporate>
              </StyledMobileCorporateContainer>
            </>
          )}
          {!isCorporate && (
            <div className="sticky-bottom-bar">
              {Number(merchant?.maxPacDiscountInOffers) > 0 ? (
                !isMerchantOffline ? (
                  <StyledMobilePacBox>
                    <img
                      src={
                        CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"
                      }
                      alt={`store-detail-info-pacs`}
                      {...pacSize}
                    />
                    <span>
                      <FormattedMessage
                        id="storeCard::discount::upTo"
                        defaultMessage="Up to"
                      />
                      <Money
                        style="percent"
                        value={merchant?.maxPacDiscountInOffers}
                        currency={false}
                      />
                    </span>
                  </StyledMobilePacBox>
                ) : (
                  <StyledMobileDiscountBox>
                    <span>
                      <FormattedMessage
                        id="storeCard::discount::discountUpTo"
                        defaultMessage="Donate up to {discount} discount"
                        values={{
                          discount: (
                            <Money
                              style="percent"
                              value={merchant?.maxPacDiscountInOffers}
                              currency={false}
                            />
                          ),
                        }}
                      />
                    </span>
                  </StyledMobileDiscountBox>
                )
              ) : null}
              {(isOfferInternal || !isMerchantOffline) &&
                Number(merchant?.maxPacDiscountInOffers) > 0 && (
                  <>
                    {payWithPacs}
                    <Spacer bottom={1} />
                  </>
                )}
              <Button
                data-testid={`btn-go-to-store`}
                type="button"
                color="carrot"
                className="btn-go-to-store"
                onClick={() => handleExternalOffer()}>
                <FormattedMessage
                  id={`storeDetail::info::${isOfferInternal
                    ? "servicesButton"
                    : !isMerchantOffline
                      ? "button"
                      : "directionsButton"
                    }`}
                  defaultMessage={
                    isOfferInternal
                      ? "See merchant's services"
                      : !isMerchantOffline
                        ? "Visit the store"
                        : "Get directions"
                  }
                />
              </Button>
            </div>
          )}
        </StyledSDIMobileMainContainer>
      )}
    </>
  )
}

StoreDetailInfo.propTypes = {
  slug: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}

export default StoreDetailInfo
