import React, { Fragment } from "react"
import queryString from "query-string"
import PropTypes from "prop-types"

import {
  Money,
  Timer,
  LazyImage,
  Button,
  UseBefore,
} from "@tmu/components/common"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl"

import { isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

import {
  StyledWrapper,
  StyledSuccessDetailTitle,
  StyledSuccessPageImage,
  StyledSuccessButtons,
  StyledSuccessDetailTimer,
  StyledSuccessDetailEventSubtitle,
  StyledSuccessDonationInfo,
  StyledSuccessDonationInfoRegister,
} from "./index.styles"
import { useAuth } from "@tmu/hooks"

import { RECURRING_DONATION_TYPE } from "@tmu/apollo/constants"

const { CLOUDFLARE_IMAGE_URL } = process.env

const CampaignDonationSuccessContent = () => {
  const params = queryString.parse(location?.search, { arrayFormat: "comma" })
  const { isLoading, isAuthenticated } = useAuth()
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const { formatMessage } = useIntl()

  const isFree = params.free
  const campaignPartnerName = params.cpn
  const campaignPartnerSlug = params.cps
  const campaignStoreName = params.csn
  const username = params.cuf
  const campaignSlug = params.cs
  const campaignName = params.cn
  const isDefaultCampaign = params.cid === "true"
  const campaignDeadline = params.cd ? new Date(params.cd) : ""
  const isVoucher = params.civ === "true"
  const earnedPACValue = Number(params.epv || 0)
  const donationAmount = Number(params.da || 0)
  const email = params.email
  const donationType = params.donationType
  const isDWOR = params.dwor === "true"
  const redirectTo = params.redirectTo
  const merchantName = params.merchantName
  const isOfflineMerchant = params.iom === "true"
  const isCorporateMerchant = params.icm === "true"

  return (
    <StyledWrapper>
      {isFree && campaignPartnerName ? (
        <StyledSuccessDetailTitle data-testid="donation-success-details">
          <FormattedMessage
            id="campaign::donationSuccess::processedTextBook"
            defaultMessage="You have booked a spot at <partner>{partnerName}</partner> event!"
            values={{
              partner: (...chunks) => (
                <span key="partner">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              partnerName: campaignPartnerName,
            }}
          />
        </StyledSuccessDetailTitle>
      ) : campaignPartnerName && donationAmount ? (
        <StyledSuccessDetailTitle data-testid="donation-success-details">
          <FormattedMessage
            id="campaign::donationSuccess::processedText1"
            defaultMessage="You have donated <pacs>{amount}</pacs> to <partner>{partnerName}</partner>"
            values={{
              pacs: (...chunks) => (
                <span key="processedText">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              partner: (...chunks) => (
                <span key="partner">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              partnerName: campaignPartnerName,
              amount: <Money value={donationAmount || ""} />,
            }}
          />
        </StyledSuccessDetailTitle>
      ) : null}
      {/* {tiers?.length > 0 && (
          <StyledCampaignTiers>{tiers.join(", ")}</StyledCampaignTiers>
        )} */}
      {(!isFree && isCorporateMerchant && campaignStoreName) ||
      (!isFree && !isCorporateMerchant && username) ? (
        <StyledSuccessDetailEventSubtitle data-testid="donation-success-event-details">
          <FormattedMessage
            id={
              isCorporateMerchant
                ? "campaign::donationSuccess::corporateCampaignText"
                : "campaign::donationSuccess::eventSubtitleLong"
            }
            defaultMessage="<user>{userName}</user>'s campaign <link>{campaignName}</link>"
            values={{
              link: (...chunks) => (
                <Link
                  to={`/campaigns/${campaignSlug}`}
                  className="name"
                  aria-label={"campaign " + campaignSlug}>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </Link>
              ),
              user: (...chunks) => (
                <span key="partner">
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </span>
              ),
              userName: isCorporateMerchant ? campaignStoreName : username,
              campaignName: campaignName,
            }}
          />
        </StyledSuccessDetailEventSubtitle>
      ) : null}
      {isDefaultCampaign &&
        (donationType === RECURRING_DONATION_TYPE.MONTHLY ||
          donationType === RECURRING_DONATION_TYPE.ANNUALLY) && (
          <FormattedMessage
            id="campaign::donationSuccess::isRecurringTitle"
            defaultMessage="Next donation will occur next {donationType}"
            tagName="h5"
            values={{
              donationType:
                donationType === RECURRING_DONATION_TYPE.MONTHLY
                  ? formatMessage({
                      id: "campaign::donationSuccess::recurringMonthly",
                      defaultMessage: "month",
                    })
                  : donationType === RECURRING_DONATION_TYPE.ANNUALLY
                  ? formatMessage({
                      id: "campaign::donationSuccess::recurringAnnually",
                      defaultMessage: "year",
                    })
                  : "",
            }}
          />
        )}
      <StyledSuccessPageImage>
        <LazyImage
          altName="donation-success"
          src={
            CLOUDFLARE_IMAGE_URL + "/static/assets/images/tmu_celebration.gif"
          }
          width="400"
          fit="cover"
        />
        {isFree ? (
          <FormattedMessage
            id="campaign::donationSuccess::thanksBook"
            defaultMessage="Thank you for your booking."
            tagName="p"
          />
        ) : (
          <FormattedMessage
            id="campaign::donationSuccess::thanks"
            defaultMessage="Thank you for your donation."
            tagName="p"
          />
        )}
      </StyledSuccessPageImage>
      {redirectTo?.length > 0 && (
        <StyledSuccessDetailTimer>
          <FormattedMessage
            id="campaign::donationSuccess::processedText2"
            defaultMessage="In {timer} you will be redirected to {merchantName} cart page or press <here>here</here> if you don’t want to wait."
            values={{
              timer: (
                <Timer
                  onCompletedCb={() => donateByShoppingOnComplete(redirectTo)}
                />
              ),
              here: (...chunks) => (
                <Button
                  label="here"
                  variant="text"
                  color="white"
                  onClick={() => {
                    window.location.href = redirectTo
                  }}>
                  {chunks.map((chunk, i) => (
                    <Fragment key={i}>{chunk}</Fragment>
                  ))}
                </Button>
              ),
              merchantName: merchantName,
            }}
          />
        </StyledSuccessDetailTimer>
      )}
      {email ? (
        <FormattedMessage
          id="campaign::donationSuccess::email"
          defaultMessage="We have sent a confirmation letter of your donation to <strong>{email}</strong>."
          values={{
            email: email,
            strong: (...chunks) => (
              <strong key="emailStrong" className="emailStrong">
                {chunks}
              </strong>
            ),
          }}
          tagName="p"
        />
      ) : null}
      {!earnedPACValue || !isAuthenticated ? (
        <></>
      ) : (
        <StyledSuccessDonationInfo>
          <p>
            <FormattedMessage
              id="campaign::donationSuccess::receivedPac"
              defaultMessage="You have received <earned>{earnedPACs}</earned> PACs for your <wallet>PAC Wallet</wallet> for this donation"
              values={{
                wallet: (...chunks) => (
                  <Link
                    to="/dashboard/donors/wallet"
                    key="usePacWallet"
                    aria-label="check pac wallet">
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>{chunk}</Fragment>
                    ))}
                  </Link>
                ),
                earnedPACs: <Money value={earnedPACValue} currency={false} />,
                earned: (...chunks) => (
                  <span key="earned">
                    {chunks.map((chunk, i) => (
                      <Fragment key={i}>
                        <img
                          className="pacs-pacs-image"
                          src={
                            CLOUDFLARE_IMAGE_URL +
                            "/static/assets/images/pac.svg"
                          }
                          alt={`pacs`}
                          width={16}
                          height={16}
                        />
                        {chunk}
                      </Fragment>
                    ))}
                  </span>
                ),
              }}
            />{" "}
            {isDWOR ? (
              <FormattedMessage
                id="campaign::donationSuccess::earnedRegistrationBonus"
                defaultMessage="and <earned>{earnedPACs}</earned> PACs as a bonus for registration!"
                values={{
                  earnedPACs: <Money value={Number(10)} currency={false} />, //TODO: Value will be updated after BE.
                  earned: (...chunks) => (
                    <span key="earned">
                      {chunks.map((chunk, i) => (
                        <Fragment key={i}>
                          <img
                            className="pacs-pacs-image"
                            src={
                              CLOUDFLARE_IMAGE_URL +
                              "/static/assets/images/pac.svg"
                            }
                            alt={`pacs`}
                            width={16}
                            height={16}
                          />
                          {chunk}
                        </Fragment>
                      ))}
                    </span>
                  ),
                }}
              />
            ) : null}
          </p>
        </StyledSuccessDonationInfo>
      )}

      <UseBefore
        isVoucher={!isFree && isVoucher}
        endDate={campaignDeadline}
        isCampaign={true}
      />

      {!isFree &&
      isDefaultCampaign &&
      (donationType === RECURRING_DONATION_TYPE.MONTHLY ||
        donationType === RECURRING_DONATION_TYPE.ANNUALLY) ? (
        <FormattedMessage
          id="campaign::donationSuccess::isRecurringText"
          defaultMessage="You can manage your recurring donations from <dashboard>your dashboard</dashboard>."
          values={{
            dashboard: (...chunks) => (
              <Link
                to="/dashboard/profile"
                key="profile"
                aria-label="dashboard profile">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </Link>
            ),
          }}
          tagName="p"
        />
      ) : !isFree && !isDefaultCampaign && campaignPartnerSlug ? (
        <FormattedMessage
          id="campaign::donationSuccess::isNotCharities"
          defaultMessage="<here>Click here</here> if you want to set a recurring donation to <partner>{partnerName}</partner>"
          values={{
            here: (...chunks) => (
              <Link
                to={`/charities/${campaignPartnerSlug}`}
                key="here"
                aria-label="campaign partner">
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </Link>
            ),
            partner: (...chunks) => (
              <>
                {chunks.map((chunk, i) => (
                  <Fragment key={i}>{chunk}</Fragment>
                ))}
              </>
            ),
            partnerName: campaignPartnerName,
          }}
          tagName="p"
        />
      ) : null}

      {!redirectTo && (
        <div className={!isTablet ? "sticky-bottom-bar" : ""}>
          <StyledSuccessButtons>
            <Button
              label="EXPLORE STORES"
              variant="outlined"
              color="transparent"
              onClick={() => {
                navigate("/offers?store=true")
              }}>
              <FormattedMessage
                id="campaign::donationSuccess::exploreStores"
                defaultMessage="EXPLORE STORES"
              />
            </Button>
            <Button
              label="EXPLORE CHARITIES"
              variant="outlined"
              color="blue"
              onClick={() => {
                navigate("/campaigns?charity=true")
              }}>
              <FormattedMessage
                id="campaign::donationSuccess::exploreCharities"
                defaultMessage="EXPLORE CHARITIES"
              />
            </Button>
          </StyledSuccessButtons>
        </div>
      )}
    </StyledWrapper>
  )
}

CampaignDonationSuccessContent.propTypes = {
  campaign: PropTypes.object,
  donation: PropTypes.object,
  redirectTo: PropTypes.string,
  donationType: PropTypes.string,
  isTablet: PropTypes.bool,
}

export default CampaignDonationSuccessContent
