import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"

export const StyledCardListContainer = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 0 auto;

  .card {
    margin-bottom: 1.875rem;
    max-width: ${(p) => p.theme.breakpoints.phone};
  }

  .fund-amount p {
    display: inline;
  }
`

export const StyledWrapper = styled.div``

export const StyledContent = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin-bottom: 2rem;
  padding-right: 1rem;
  margin-top: 1.5rem;
  .media-box {
    padding-bottom: 0;
    margin: 1.5625rem 0;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }

  @media only screen and (max-width: 26.5625rem) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  @media only screen and (min-width: ${(p) =>
      p.theme.breakpoints.tablet}) and (max-width: 85.625rem) {
    padding: 0 1rem;
  }

  @media only screen and (max-width: ${(p) => p.theme.breakpoints.phone}) {
    padding-right: 0;
  }
`

export const StyledCampaignNum = styled.div`
  display: block;
  color: rgba(0, 0, 0, 1);
  font-size: 1rem;
  align-self: center;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-top: 0.9375rem;
  span {
    color: rgba(0, 0, 0, 1) !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledHorizontalRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledPartnerCategories = styled.div`
  border-radius: 0.1875rem;
  background-color: rgba(17, 17, 17, 1);
  color: white;
  font-size: 0.8125rem;
  line-height: 1.875rem;
  text-align: center;
  text-transform: uppercase;
`

export const StyledPartnerMetaItems = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0.4375rem;
`

export const StyledPartnerMetaItem = styled.div`
  margin-right: 1.25rem;

  svg,
  i {
    margin-right: 0.325rem;
  }
`

export const StyledPartnerLogoContainer = styled.div`
  align-self: start;
  justify-self: center;
  margin-right: 1.5rem;
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    overflow: hidden;
    object-fit: contain;
    border-radius: 0.3125rem;
  }
`

export const StyledSocialIcons = styled.div`
  font-size: 1.8rem;
  background: white;
  cursor: pointer;

  a {
    color: rgba(0, 0, 0, 1);
    font-size: 1.25rem;
    margin-right: 0.5rem;
  }
`

export const StyledSectionTitle = styled.h3`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-size: 0.75rem;

  svg,
  i {
    margin-right: 0.9375rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  p {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => p.theme.colors.pitchBlack};
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 0.625rem;
    line-height: 1rem;

    margin-top: 0.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
      line-height: 1.25rem;
      margin-top: 0.75rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-top: 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-top: 2rem;
    }
  }
`

export const StyledSectionStoreSupporterTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  color: ${(p) => p.theme.colors.pitchBlack};
  text-transform: none;
  font-size: 1.125rem;
  line-height: 1.75rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    font-size: 1.5rem;
    line-height: 2.125rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
    line-height: 2.75rem;
  }
`

export const StyledPartnerDescription = styled.div`
  img {
    width: 100%;
  }

  p {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    span {
      color: ${(p) => p.theme.colors.pitchBlack} !important;
    }
  }

  color: ${(p) => p.theme.colors.pitchBlack};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  word-break: break-word;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
    line-height: 2rem;
    p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }

  span {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 0.75rem;
    line-height: 1.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`

export const StyledShortDescription = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  span {
    color: ${(p) => p.theme.colors.pitchBlack} !important;
  }
`

export const StyledTabs = styled.div`
  margin-bottom: 2rem;
  padding: 1.5625rem 0 4rem 1rem;

  .react-tabs__tab {
    color: ${(p) => p.theme.colors.gondola};
    opacity: 0.3;
    outline: none;
    cursor: pointer;
    padding-bottom: 0.7rem;
    transition: 0.3s;
    font-size: 1.5rem;
    background: none;
    border: none;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    padding-right: 0;
    margin-right: 2.5rem;
  }

  .react-tabs__tab--selected {
    border-bottom: 0.125rem solid ${(p) => p.theme.colors.primaryBrandTextColor};
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    opacity: 1;
  }

  &,
  p {
    color: ${(p) => p.theme.colors.disabled};
    font-weight: ${(p) => p.theme.fontFamilies.semiBolder};
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 0.9375rem;
    padding-right: 1.938rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    line-height: 1.75rem;
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  img {
    width: 100%;
  }

  h3 {
    margin: 0 0 1rem 0;
    line-height: 1.8125rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  width: 100%;
  padding: 0;

  &,
  p {
    padding-right: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
`

export const StyledGalleryContainer = styled.div`
  max-width: 50rem;
`

export const StyledFavoriteNGOButton = styled.div`
  height: fit-content;
  z-index: 2;
  svg {
    height: 1.25rem;
    cursor: pointer;
  }

  .favorite {
    max-width: 25rem;
    opacity: 1 !important;
  }

  #favorite {
    box-shadow: rgb(0 0 0 / 15%) 0 0 0.75rem !important;
    padding: 0.8125rem;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
    #favorite {
      top: 3.4375rem !important;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    svg {
      font-size: 1.5rem;
      height: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    svg {
      padding-right: 1.5rem;
    }

    #favorite {
      top: 4rem !important;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg {
      font-size: 2rem !important;
      height: 2rem !important;
      padding-right: 2.5rem;
    }

    #favorite {
      top: 6.125rem !important;
    }
  }
`

export const StyledViewMoreButton = styled(Button)`
  margin: 0 auto;
`

export const StyledCampaingWrapper = styled.div`
  p {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontWeights.black};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    text-transform: uppercase;
    padding-top: 0.5rem;
    line-height: 1.5rem;
  }
`

export const StyledHeader = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 2rem;
  }
`

export const StyledTitle = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  text-align: left;
  word-break: break-word;

  &::first-letter {
    text-transform: capitalize;
  }

  font-size: 1.5rem;
  line-height: 2rem;

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    text-align: left;
    &::first-letter {
      text-transform: capitalize;
    }

    font-size: 1.5rem;
    line-height: 2rem;
  }

  .caption {
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    i {
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    .caption {
      font-size: 0.75rem;
      i {
        width: 1.3125rem;
        height: 1.3125rem;
      }
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    line-height: 4.0625rem;
    .caption {
      font-size: 1rem;
      i {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
`

export const StyledCampaignStats = styled.div`
  span:first-of-type.fund {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .support {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    text-align: right;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  margin-top: 1rem;

  > p {
    margin: 0 0 2.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    > p {
      margin: 2.5rem 0;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 3rem;
    margin-bottom: 3rem;

    span:first-of-type.fund {
      margin-bottom: 1rem;
    }
  }
`

export const StyledParagraph = styled.p`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;

  span {
    color: ${(p) => p.theme.colors.pitchBlack} !important;
  }
`

export const StyledSupportEndedButton = styled(Button)`
  text-align: center;
  height: 4.0625rem;
  width: 100%;
  margin: 2.5rem 0 2.6875rem;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;
  white-space: pre-wrap;

  svg {
    font-size: 2.3125rem;
    height: 2.3125rem;
  }
`

export const StyledGoallessCampaignStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5rem;

  .download {
    color: ${(p) => p.theme.colors.blue};
    font-size: 0.9rem;
    margin: 0 0 0 0.8rem;
    cursor: pointer;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: ${(p) => (p.isTablet ? "space-between" : "auto")};
    width: ${(p) => (p.isTablet ? "100%" : "auto")};

    p {
      &:first-of-type {
        margin-right: 2.5rem;
      }
      &.right {
        text-align: right;
      }
    }

    svg {
      margin: 0 0 1rem 1.5rem;
      height: 1.5rem;

      @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
        height: 2rem;
      }
    }
  }

  .funded {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    color: ${(p) => p.theme.colors.primaryBrandTextColor};
    padding-top: ${(p) => p.isFeatured && "1.5625rem"};
    font-size: 1.5rem;
    display: block;
    margin-bottom: 0.5rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 2rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      margin-bottom: 0.8rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 3rem;
      margin-bottom: 1.2rem;
    }
  }

  .funded-card {
    font-weight: ${(p) => p.theme.fontWeights.bold};
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: ${(p) => p.theme.colors.blue};
  }
`

export const StyledTooltip = styled.div`
  #campaignDetailCard {
    margin-top: 0;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    opacity: 1 !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-top: -0.3125rem;
    }
  }
`

export const StyledSupportButton = styled(Button)`
  white-space: pre-wrap;
  text-align: center;
  width: 100%;
  height: 2.5rem;
  position: relative;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.75rem;
  margin-left: unset;

  svg {
    display: block;
    font-size: 1.5rem;
    height: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 1rem;
    font-size: 0.7rem;

    svg {
      font-size: 1.5rem;
      height: 1.5rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.375rem;
    height: 3.375rem;
  }
`

export const StyledSocialShare = styled.div`
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: right;
  &.mt-0 {
    margin-top: 0rem;
  }
  .caption {
    width: unset;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      width: 4rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      width: 9.5rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      width: 13.125rem;
    }
  }
  svg {
    font-size: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-top: 1.5rem;
    height: 2rem;

    > div {
      min-width: 12rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg {
      font-size: 2rem;
      height: 2rem;
      margin-left: 2rem;
      cursor: pointer;
    }

    > div {
      min-width: 15.75rem;
    }
  }
`

export const StyledFiscalCode = styled.p`
  margin: 0.5rem 1rem 1.5rem;

  span {
    color: ${(p) => p.theme.colors.pitchBlack};
    padding-left: 0.3rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 0;
  }
`

export const StyledLogo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0;

  svg {
    height: 1.25rem;
  }

  .media-box {
    width: auto;
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    img {
      height: 3rem;
    }
    .media-box {
      width: auto;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    img {
      height: 5rem;
    }
  }
`

export const StyledLine = styled.hr`
  margin: 0 1rem;
  width: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0;
  }
`

export const StyledCampaignListWrapper = styled.div`
  max-height: 23rem;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  ::-webkit-scrollbar-track {
  }

  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.colors.lightGrey};
    border-radius: 0.25rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.colors.lightGreyHover};
  }

  > p {
    font-size: 0.75rem;
    @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 1rem;
    }
  }
`

export const StyledCampaignsWrapper = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 6rem auto;
  margin-right: 0.5rem;
  gap: 1rem;
  margin-bottom: 0.75rem;

  div > img {
    border-radius: 0.25rem;
  }

  div {
    margin: auto 0;
    color: ${(p) => p.theme.colors.pitchBlack};
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    line-height: 1.25rem;
    &:first-letter {
      text-transform: capitalize;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    grid-template-columns: 7.5625rem auto;

    img {
      width: 100%;
      height: 100%;
      max-height: 4.2088rem;
    }

    div {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
`

export const StyledSideCampaignsTitle = styled.p`
  margin: 2rem 0 1rem 1rem !important;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 1.5rem 0 1.5rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin: 2rem 0 1.5rem !important;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 3rem 0 1.5rem !important;
  }
`

export const StyledCreateButton = styled(Button)`
  box-sizing: border-box;
  text-align: center;
  height: 2.5rem;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
  width: stretch;
  margin: 1rem 1rem 2.6875rem;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  gap: 0.75rem;

  span {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-size: 0.75rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin: 2rem 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin: 3rem 0;
    height: 3.375rem;

    span {
      font-size: 1rem;
    }
  }
`

export const StyledSocialLink = styled.div`
  a {
    display: block;
    color: ${(p) => p.theme.colors.softGreen};
    font-size: 1rem;
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    text-transform: capitalize;
    line-height: 1.172rem;
    margin-bottom: 0.75rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }
`

export const StyledCampaignName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  text-align: left;
`

export const StyledPACMultiplier = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  background: ${(p) => p.theme.colors.carrot};
  color: ${(p) => p.theme.colors.white};

  margin-left: 0.5rem;
  position: relative;
  border-radius: 0.25rem;
  min-width: 3.125rem;
  height: 1.5625rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.375rem;

  img {
    width: 0.75rem;
    height: 0.75rem;
    position: relative;
    top: 0.1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    min-width: 3.0625rem;
    height: 1.5625rem;
    padding: 0.25rem 0;

    img {
      width: 0.75rem;
      height: 0.75rem;
      top: 0.125rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0.125rem;
    img {
      width: 0.875rem;
      height: 0.875rem;
      top: 0.125rem;
    }
  }
`
export const StyledHeaderAndShare = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;

    svg:last-child {
      margin-top: -0.8375rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg:last-child {
      margin-top: unset;
    }
  }

  > div:first-child {
    width: 92.5%;
  }

  > div:last-child {
    width: 7.5%;

    div:first-child {
      margin: 0;
    }
  }
`
