import { API_PERMISSIONS, VISIBILITY_STATUS } from "@tmu/apollo/constants"
import {
  Date as CustomDate,
  CustomModal,
  Spinner,
  Table,
  TooltipSelectMenu,
} from "@tmu/components/common"
import Money from "@tmu/components/common/Money"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { useOffers, useToast } from "@tmu/hooks"
import { HomePageContext } from "@tmu/src/context/homePageContext"
import { format } from "date-fns"
import { differenceInHours } from "@tmu/utils/date"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  StyledSimpleTextContent,
  StyledUsedCardTitle,
} from "../../dashboardPages/Profile/index.styles"
import {
  StyledLeftStockItem,
  StyledPageWrapper,
  StyledServiceNameWrapper,
  StyledTable,
  StyledTooltip,
} from "./index.styles"

const ServicesTable = ({ onRowSelect, searchText, apiPermissions }) => {
  const { formatMessage, locale } = useIntl()
  const { success, error, info } = useToast()
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])
  const [firstLoad, setFirstLoad] = useState(false)
  const {
    callMerchantOffers,
    merchantService,
    partialUpdateOffer,
    deleteOffer,
  } = useOffers()
  const { data, refetch, loading, fetchMore } = merchantService
  const pageInfo = data?.pageInfo
  const offers = data?.edges?.map((item) => item?.node)
  const selectMenuRef = useRef()
  const [menuStatus, setMenuStatus] = useState({})
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const { setGlobalSpinnerStatus } = useContext(HomePageContext)
  const [offset, setOffset] = useState(0)
  const variables = {
    first: pageSize,
    offerType_In: ["INTERNAL"],
    search: searchText?.trim(),
    offset: offset * pageSize,
  }

  useEffect(() => {
    callMerchantOffers({
      variables,
      onCompleted: () => setFirstLoad(true),
      fetchPolicy: "network-only",
    })
  }, [searchText, pageSize, offset])

  const updateOffer = (variables) => {
    setGlobalSpinnerStatus(true)
    partialUpdateOffer({
      variables: {
        input: {
          ...variables,
        },
      },
    })
      .then((result) => {
        if (result?.errors?.length) {
          result?.errors?.forEach((err) => error(err?.message))
          return
        }

        refetch({ search: searchText })
      })
      .catch((err) => {
        error(err?.message)
      })
      .finally(() => setGlobalSpinnerStatus(false))
  }

  const { isTablet, isWide } = getAllScreenTypes()

  const tableData = !offers
    ? []
    : offers
        ?.filter((item) => !item?.isDefaultOffer)
        ?.map((item) => {
          const variations = item?.productVariation
          const priceArr = variations
            ?.filter((item) => !isNaN(item?.price))
            ?.map((item) => Number(item?.price))

          const priceText = Math.min(...priceArr)

          return {
            id: item?.id,
            name: item?.name,
            startDate: item?.startDate,
            endDate: item?.endDate,
            maxPacDiscount: item?.maxPacDiscount,
            minPrice: priceText ?? item?.minPrice,
            slug: item.slug,
            productVariation: item?.productVariation,
            service: item,
            status:
              item.visibilityStatus === VISIBILITY_STATUS.ARCHIVED ||
              (item.endDate &&
                differenceInHours(new Date(item.endDate), new Date()) <= 0)
                ? formatMessage({
                    id: `visibility::type::ENDED`,
                    defaultMessage: "ENDED",
                  })
                : item.visibilityStatus === VISIBILITY_STATUS.LISTED
                ? formatMessage({
                    id: `visibility::type::PUBLIC`,
                    defaultMessage: "PUBLIC",
                  })
                : item.visibilityStatus === VISIBILITY_STATUS.UNLISTED
                ? formatMessage({
                    id: `visibility::type::PRIVATE`,
                    defaultMessage: "PRIVATE",
                  })
                : item.visibilityStatus === VISIBILITY_STATUS.UPCOMING
                ? formatMessage({
                    id: `visibility::type::UPCOMING`,
                    defaultMessage: "UPCOMING",
                  })
                : "-",
          }
        })

  const StatusMessage = ({ value }) => {
    switch (value) {
      case "PUBLIC":
        return (
          <span className="tooltiptext">
            <FormattedMessage
              id="dashboard::statusMsg::public"
              defaultMessage="Visible for everyone"
            />
          </span>
        )
      case "PRIVATE":
        return (
          <span className="tooltiptext">
            <FormattedMessage
              id="dashboard::statusMsg::private"
              defaultMessage="Visible only through a direct link/share"
            />
          </span>
        )
      case "ENDED":
        return (
          <span className="tooltiptext">
            <FormattedMessage
              id="dashboard::statusMsg::ended"
              defaultMessage="Ended, not visible"
            />
          </span>
        )
      case "FINISHED":
        return (
          <span className="tooltiptext">
            <FormattedMessage
              id="dashboard::statusMsg::finished"
              defaultMessage="Finished, not visible"
            />
          </span>
        )

      default:
        return <></>
    }
  }

  const columns = [
    {
      Header: (props) => (
        <div className="header-text">
          <FormattedMessage
            id="dashboard::tableHeader::service"
            defaultMessage="Service"
          />
        </div>
      ),
      Cell: (props) => (
        <StyledServiceNameWrapper>
          {isTablet ? (
            props?.value
          ) : (
            <div>
              {props?.value}
              <p className="mobile-date">
                <CustomDate
                  value={props?.row?.original?.startDate}
                  showYear={true}
                  second="numeric"
                />
              </p>
            </div>
          )}
        </StyledServiceNameWrapper>
      ),
      accessor: "name",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::status"
            defaultMessage="Status"
          />
        </div>
      ),
      Cell: ({ value }) => (
        <StyledTooltip>
          {value}
          <StatusMessage value={value} />
        </StyledTooltip>
      ),
      accessor: "status",
      headerClassName: "align-header-right",
      disableSort: true,
    },

    {
      Header: (props) => (
        <div className="header-text header-icon align-center">
          <FormattedMessage
            id="dashboard::tableHeader::price"
            defaultMessage="Price"
          />
        </div>
      ),
      Cell: (props) => {
        const value = props?.value
        const fromText =
          props?.row?.original?.productVariation?.length > 1
            ? formatMessage({
                id: "dashboard::tableHeader::from",
                defaultMessage: "from",
              })
            : ""
        return (
          <div className="align-center price-cell">
            {value && (
              <>
                {fromText} <Money value={value} />
              </>
            )}
          </div>
        )
      },
      accessor: "minPrice",
      headerClassName: "align-header-center",
      rowClass: "price-cell",
      disableSort: false,
    },
    {
      Header: (props) => (
        <div className="header-text align-center">
          <FormattedMessage
            id="dashboard::tableHeader::startDate"
            defaultMessage="Start"
          />
        </div>
      ),
      Cell: ({ value }) =>
        value &&
        new Date(value) instanceof Date &&
        format(new Date(value), "MMM dd, yyyy, HH:mm:ss"),
      accessor: "startDate",
      disableSort: false,
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::finishDate"
            defaultMessage="Finish"
          />
        </div>
      ),
      Cell: ({ value }) =>
        value && new Date(value) instanceof Date ? (
          format(new Date(value), "MMM dd, yyyy, HH:mm:ss")
        ) : (
          <span className="symbol">∞</span>
        ),
      accessor: "endDate",
      disableSort: false,
    },
    {
      Header: (props) => (
        <div
          className="header-text align-right"
          style={{ width: "max-content" }}>
          <FormattedMessage
            id="dashboard::tableHeader::stockLeft"
            defaultMessage="Stock Left"
          />
        </div>
      ),
      Cell: (props) => {
        const radioOptions = props?.row?.values?.productVariation
          .map((item) => {
            return {
              id: item?.id,
              pacDiscount: item?.pacDiscount,
              isUnlimitedTickets: item?.isUnlimitedTickets,
              isSoldOut: item?.isSoldOut,
              maxTickets: item?.maxTickets,
              price: Number(item?.price),
              isActive: item?.isActive,
              stockLeft: item?.stockLeft,
            }
          })
          .filter((item) => item?.isActive)

        radioOptions.sort((a, b) => a?.price - b?.price)

        const formattedData = radioOptions?.map((item, index) => {
          if (item?.isUnlimitedTickets) {
            return (
              <span className="symbol" key={index}>
                ∞
              </span>
            )
          } else {
            return (
              <span
                key={index}
                className={`${!item?.stockLeft ? "red-text" : ""}`}>
                {item?.stockLeft ?? 0}
              </span>
            )
          }
        })

        const result = formattedData.map((element, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span> | </span>}
            {element}
          </React.Fragment>
        ))

        return <StyledLeftStockItem>{result}</StyledLeftStockItem>
      },
      accessor: "productVariation",
      headerClassName: "align-header-right",
      disableSort: true,
    },
    {
      Header: (props) => (
        <div className="header-text align-right nowrap">
          <FormattedMessage
            id="dashboard::tableHeader::pacDiscount"
            defaultMessage="PAC Discount"
          />
        </div>
      ),
      Cell: ({ value }) => (
        <div className="align-right">
          {value && <Money value={value} currency={false} style="percent" />}
        </div>
      ),
      accessor: "maxPacDiscount",
      headerClassName: "align-header-right",
      disableSort: false,
    },
    {
      Cell: (props) => {
        const { row } = props
        const { index, original } = row
        const service = original?.service
        const visibilityStatus = service?.visibilityStatus
        return (
          // apiPermissions?.includes(API_PERMISSIONS.MERCHANT_UPDATE_PRODUCT) && (
          <TooltipSelectMenu
            ref={selectMenuRef}
            menuIsOpen={menuStatus[index]}
            row={row}
            menuWidth={menuWidth}
            tooltipHeight={tooltipHeight}
            options={getMenuOptions(visibilityStatus)}
            onMouseLeave={() => {
              const temp = {}
              temp[index] = 0
              setMenuStatus({ ...menuStatus, ...temp })
            }}
            onMouseOver={() => {
              if (menuStatus[index] === 1) {
                return
              }
              const temp = {}
              temp[index] = 1
              Object.keys(menuStatus)?.forEach((ind) => {
                if (ind != index) {
                  temp[ind] = 0
                }
              })
              setMenuStatus({ ...menuStatus, ...temp })
            }}
          />
          // )
        )
      },
      id: "edit",
      disableSort: true,
    },
  ]

  const hiddenColumns = isTablet
    ? []
    : ["status", "startDate", "endDate", "edit"]

  const handleRowClick = (row, index) => {
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  const handleSort = (sortBy) => {
    setSortInfo(sortBy)

    const sortValue = getSortValue(sortBy)
    callMerchantOffers({
      variables: { ...variables, orderBy: sortValue },
    })
  }

  const getSortValue = (sortBy) => {
    const sortData = sortBy?.at(0)
    const direction = sortData?.desc === true ? "-" : ""
    let sortField = null

    switch (sortData?.id) {
      case "date":
        sortField = "created"
        break
      default:
        sortField = sortData?.id
        break
    }
    return sortField ? direction + sortField : null
  }

  const menuItemDelete = {
    value: "delete",
    label: formatMessage({
      id: "offerTable::rowMenu::delete",
      defaultMessage: "Delete",
    }),
    action: (row) => {
      setDeleteModalOpen(true)
      setSelectedRow(row)
      setMenuStatus({})
    },
  }

  const menuItemArchive = {
    value: "archive",
    label: formatMessage({
      id: "offerTable::rowMenu::archive",
      defaultMessage: "Archive",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.ARCHIVED,
        id: row?.original?.id,
      })
    },
  }

  const menuItemUnlist = {
    value: "unlist",
    label: formatMessage({
      id: "offerTable::rowMenu::unlist",
      defaultMessage: "Unlist",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.UNLISTED,
        id: row?.original?.id,
      })
    },
  }

  const menuItemList = {
    value: "list",
    label: formatMessage({
      id: "offerTable::rowMenu::list",
      defaultMessage: "List",
    }),
    action: (row) => {
      updateOffer({
        visibilityStatus: VISIBILITY_STATUS.LISTED,
        id: row?.original?.id,
      })
    },
  }

  const getMenuOptions = (visibilityStatus) => {
    const otherOptions = []

    if (visibilityStatus !== VISIBILITY_STATUS.ARCHIVED) {
      otherOptions.push(menuItemArchive)
    }

    if (visibilityStatus === VISIBILITY_STATUS.LISTED) {
      otherOptions.push(menuItemUnlist)
    } else if (visibilityStatus === VISIBILITY_STATUS.UNLISTED) {
      otherOptions.push(menuItemList)
    }

    otherOptions.push(menuItemDelete)

    const options = [
      {
        value: "edit",
        label: formatMessage({
          id: "serviceTable::rowMenu::edit",
          defaultMessage: "Edit",
        }),
        action: (row) => {
          navigate(`/dashboard/merchants/services/edit?id=${row?.original?.id}`)
        },
      },
      ...otherOptions,
      // {
      //   value: "copyWithNewPrice",
      //   label: formatMessage({
      //     id: "serviceTable::rowMenu::copyWithNewPrice",
      //     defaultMessage: "Copy with new price",
      //   }),
      // },
      {
        value: "copyLinkToTheService",
        label: formatMessage({
          id: "serviceTable::rowMenu::copyLinkToTheService",
          defaultMessage: "Copy link to the service",
        }),
        action: (row) => {
          const port = window.location.port
          // const portStr = port?.length > 0 ? `:${port}` : port
          const serviceUrl = `${window.location.origin}/${locale}/services/${row?.original?.slug}`
          navigator.clipboard.writeText(serviceUrl)
          const copiedMessage = formatMessage({
            id: "ui::copiedToClipboard",
            defaultMessage: "Copied to clipboard!",
          })
          success(copiedMessage)
        },
      },
      {
        value: "viewServicePage",
        label: formatMessage({
          id: "serviceTable::rowMenu::viewServicePage",
          defaultMessage: "View service page",
        }),
        action: (row) => {
          navigate(`/services/${row?.original?.slug}`)
        },
      },
    ]

    return options
  }

  const menuWidth = isWide ? 220 : 160
  const tooltipHeight = 190

  const handleConfirmDelete = () => {
    deleteOffer({ variables: { id: selectedRow?.original?.id } })
      .then(({ data, errors }) => {
        if (data?.deleteOffer?.success) {
          info(
            formatMessage({
              id: "dashboard::services::deleteMessage",
              defaultMessage: "Your service is deleted!",
            })
          )
        }
        error(errors?.message ?? errors?.[0]?.message)
      })
      .catch((err) => {
        error(
          err?.message ??
            formatMessage({
              id: "dashboard::stores::errorMessage",
              defaultMessage: "An error occurred",
            })
        )
      })
      .finally(() => {
        setGlobalSpinnerStatus(false)
        refetch()
      })
  }

  const handleCancelDelete = () => {
    setDeleteModalOpen(false)
    setSelectedRow(null)
  }

  return !firstLoad || loading ? (
    <Spinner condensed />
  ) : tableData.length > 0 ? (
    <StyledPageWrapper>
      <StyledTable>
        <Table
          columns={columns}
          data={tableData}
          hiddenColumns={hiddenColumns}
          headerLineSeperator={true}
          onRowClick={!isTablet ? handleRowClick : null}
          initialSort={sortInfo}
          handleSort={handleSort}
          options={{ sort: true }}
          paginationOptions={{
            pageCount: Math.ceil((data?.totalCount - 1) / pageSize),
            handlePageClick: ({ selected }) => {
              setOffset(selected)
            },
            handlePageSizeChange: (size) => {
              setPageSize(size)
            },
            initialPage: offset,
            initialPageSize: pageSize,
            pageSizesLabel:
              isTablet &&
              formatMessage({
                id: "serviceTable::pagination::servicesOnPage",
                defaultMessage: "Services on page",
              }),
            isTransparent: !isTablet,
            customStyles: { bottom: !isTablet ? "5rem" : "0" },
          }}
          hasPagination={true}
        />
      </StyledTable>

      {isDeleteModalOpen && (
        <CustomModal
          isModalOpen={isDeleteModalOpen}
          style={isTablet && { textAlign: "unset" }}
          children={
            <>
              <StyledSimpleTextContent className="delete-account-text"></StyledSimpleTextContent>
              <Spacer bottom={isWide ? 3.5 : 1.5} />
            </>
          }
          header={
            <StyledUsedCardTitle>
              <FormattedMessage
                id="dashboard::services::confirmStop"
                defaultMessage="Are you sure to finish?"
              />
            </StyledUsedCardTitle>
          }
          isCloseIcon={true}
          cancelButtonText={
            <FormattedMessage
              id="dashboard::campaigns::modalNo"
              defaultMessage="No"
            />
          }
          cancelButtonAction={() => {
            handleCancelDelete()
          }}
          confirmButtonText={
            <FormattedMessage
              id="dashboard::campaigns::modalYes"
              defaultMessage="Yes"
            />
          }
          confirmButtonAction={() => {
            handleConfirmDelete()
            handleCancelDelete()
          }}
          modalActionsStyle={{ display: "flex" }}
          isMobile={!isTablet}
          isFull={false}
        />
      )}
    </StyledPageWrapper>
  ) : (
    <EmptyTable
      emptyMessage={
        <FormattedMessage
          id="dashboard::partner::services::empty"
          defaultMessage="It seems no service yet"
        />
      }
    />
  )
}

export default ServicesTable
