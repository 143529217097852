import { useMutation } from "@apollo/client"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useApolloApiClients } from "@tmu/apollo/client"
import {
  DEFAULT_MIN_TIER_AMOUNT,
  VISIBILITY_STATUS,
} from "@tmu/apollo/constants"
import {
  CREATE_MERCHANT_OFFER_PRODUCT_VARIATION,
  UPDATE_MERCHANT_OFFER_PRODUCT_VARIATION,
} from "@tmu/apollo/dashboard/mutations/merchant"
import {
  CustomModal,
  OnSubmitValidationError,
  Spinner,
} from "@tmu/components/common"
import SEO from "@tmu/components/seo"
import { HomePageContext } from "@tmu/context/homePageContext"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import {
  useAllOfferCategories,
  useAuth,
  useDefaultMerchant,
  useOffers,
  useToast,
} from "@tmu/hooks"
import { differenceInDays } from "@tmu/utils/date"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { capitalize } from "@tmu/utils/string"
import {
  agreeTerms,
  categoryList,
  countryRequired,
  dateRequired,
  dateRequiredWhenNoDeadline,
  description,
  imageOnlyRequired,
  produtVariant,
  serviceName,
  urlFormat,
  urlFormatHttp,
  customAmountProductVariant,
} from "@tmu/utils/validation"
import { Formik } from "formik"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import queryString from "query-string"
import { useContext, useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import {
  StyledSimpleTextContent,
  StyledUsedCardTitle,
} from "../../Profile/index.styles"
import { LeaveModal } from "./LeaveModal"
import { ServiceCreationForm } from "./ServiceCreationForm"
import { StyledHeader, StyledTitle, StyledWrapper } from "./index.styles"

const ServiceForm = ({ location }) => {
  const params = queryString.parse(location?.search, { arrayFormat: "comma" })
  const { loading: loadingProfile } = useAuth()
  const { defaultMerchant } = useDefaultMerchant({ onlyAddress: true })
  const [isEnglishSectionOpen, setEnglishSectionOpen] = useState(false)
  const [isItalianSectionOpen, setItalianSectionOpen] = useState(false)
  const [isSpanishSectionOpen, setSpanishSectionOpen] = useState(false)
  const [isServiceBeingCreated, setServiceBeingCreated] = useState(false)

  const { formatMessage, locale } = useIntl()
  const localeSuffix = capitalize(locale)
  const { merchantClient } = useApolloApiClients()
  const { success, error } = useToast()
  const { allOfferCategories } = useAllOfferCategories()
  const isMerchantOffline = params?.offline === "true"
  const {
    getOfferDetail,
    offerDetailLoading,
    partialUpdateOffer,
    createOffer,
    offerSubmitForReview: submitForReview,
    offerDetailLoading: loading,
  } = useOffers()
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false)
  const [isStopModalOpen, setStopModalOpen] = useState(false)
  const [service, setService] = useState()
  const { isTablet, isWide } = getAllScreenTypes()
  const { menuConfig, setMenuConfig } = useContext(HomePageContext)
  const [isVoucher, setIsVoucher] = useState(params?.isVoucher === "true")
  const [isEventOffer, setIsEventOffer] = useState(params?.event === "true")
  const formRef = useRef()
  const offerId = params?.id

  useEffect(() => {
    if (offerId)
      getOfferDetail({
        variables: { id: offerId },
        fetchPolicy: "network-only",
      }).then(({ data }) => {
        setService(data?.offer)
      })
  }, [offerId])

  useEffect(() => {
    if (service) {
      setIsVoucher(service?.isVoucher)
    }
  }, [service])

  const isUpdate = service?.id?.length > 0

  const unknownError = formatMessage({
    id: "dashboard::campaignForm::errorMessage",
    defaultMessage: "An error occurred",
  })

  const newServiceText = formatMessage({
    id: "dashboard::serviceForm::pageTitle",
    defaultMessage: "New Service",
  })

  const newVoucherText = formatMessage({
    id: "dashboard::offerForm::pageTitleVoucher",
    defaultMessage: "New voucher",
  })

  const newEventText = formatMessage({
    id: "dashboard::offerForm::pageTitleEvent",
    defaultMessage: "New Event",
  })

  const updateServiceText = formatMessage({
    id: "dashboard::serviceForm::update",
    defaultMessage: "Update Service",
  })

  const updateVoucherText = formatMessage({
    id: "dashboard::serviceForm::updateVoucher",
    defaultMessage: "Update Voucher",
  })

  const updateEventText = formatMessage({
    id: "dashboard::serviceForm::updateEvent",
    defaultMessage: "Update Event",
  })

  useEffect(() => {
    setMenuConfig({ ...menuConfig, hideOnDashboard: true, forceOverlay: true })
  }, [])

  const validationSchema = Yup.object().shape({
    name: serviceName({ formatMessage }),
    descriptionEn: locale === "en" && description({ formatMessage }),
    descriptionIt: locale === "it" && description({ formatMessage }),
    descriptionEs: locale === "es" && description({ formatMessage }),
    startDate: dateRequired({ formatMessage }),
    endDate: dateRequiredWhenNoDeadline({ formatMessage }),
    video: urlFormat({ formatMessage }),
    // virtualEventLink: urlFormatHttp({ formatMessage }), // todo: fix this
    image: imageOnlyRequired({ formatMessage }),
    categories: categoryList({ formatMessage }),

    // maxPacDiscount: percentage({ formatMessage }),
    productVariants: produtVariant({
      locale,
      formatMessage,
      isEnglishSectionOpen,
      isItalianSectionOpen,
      isSpanishSectionOpen,
    }),
    customAmountProductVariant: customAmountProductVariant({
      locale,
      formatMessage,
      isEnglishSectionOpen,
      isItalianSectionOpen,
      isSpanishSectionOpen,
    }),
    isTermsAndPolicyAccepted: agreeTerms({ formatMessage }),
    country: countryRequired({ formatMessage }),
  })

  const coordinates = service?.location?.coordinates

  const initialValues = {
    id: service?.id,
    name: service?.name || "",
    description: service?.description || "",
    descriptionEn: service?.descriptionEn || "",
    descriptionIt: service?.descriptionIt || "",
    descriptionEs: service?.descriptionEs || "",
    shortDescriptionEn: service?.shortDescriptionEn || "",
    shortDescriptionIt: service?.shortDescriptionIt || "",
    shortDescriptionEs: service?.shortDescriptionEs || "",
    startDate: service?.startDate ? new Date(service?.startDate) : null,
    eventStartsAt: service?.eventStartsAt
      ? new Date(service?.eventStartsAt)
      : null,
    endDate: service?.endDate ? new Date(service?.endDate) : "",
    hasNoDeadline: !!!service?.endDate,
    showAddressAndPhone: service?.showAddressAndPhone === false ? false : true,
    location:
      Array.isArray(coordinates) && coordinates?.length > 1
        ? `SRID=4326;POINT(${coordinates?.at(0)} ${coordinates?.at(1)})`
        : "",
    country: service?.country || service?.store?.defaultOffer?.country || "",
    city: service?.city || service?.store?.defaultOffer?.city || "",
    postCode: service?.postCode || service?.store?.defaultOffer?.postCode || "",
    phoneNumber:
      service?.phoneNumber || service?.store?.defaultOffer?.phoneNumber || "",
    addressLine1:
      service?.addressLine1 || service?.store?.defaultOffer?.addressLine1 || "",
    // maxPacDiscount: service?.maxPacDiscount || "",
    image: service?.image,
    eventMapLink: service?.eventMapLink,
    virtualEventLink: service?.virtualEventLink,
    isVirtualEvent: !!service?.virtualEventLink,
    isWorldwide: service?.isWorldwide,
    startDateVisibility: service?.startDateVisibility,
    isUnlisted: isMerchantOffline
      ? false
      : service?.id
      ? service?.startDateVisibility === VISIBILITY_STATUS?.UNLISTED
      : false,
    categories: service?.id
      ? service?.categories?.edges?.map(({ node }) => node.id)
      : [],
    categoryItems: service?.categories?.edges?.map(({ node }) => node),

    productVariants: service?.productVariation
      ?.filter((item) => item?.isActive && !item?.allowCustomAmount)
      ?.sort((a, b) => a?.price - b?.price) ?? [
      {
        price: DEFAULT_MIN_TIER_AMOUNT,
        [`name${localeSuffix}`]: "",
        [`description${localeSuffix}`]: "",
        [`shortDescription${localeSuffix}`]: "",
        maxTickets: "",
        pacDiscount: "",
        isUnlimitedTickets: true,
        allowCustomAmount: false,
      },
    ],
    customAmountProductVariant: service?.productVariation?.find(
      (item) => item?.allowCustomAmount
    ),
    isTermsAndPolicyAccepted: false,
    isInvoiceMandatory: !!service?.isInvoiceMandatory,
    availableCountries: service?.availableCountries,
    availableCities: service?.availableCities,
    isVoucher: isVoucher || service?.isVoucher,
    isEventOffer: isEventOffer || service?.isEventOffer,
    isReservationRequired: isVoucher
      ? service?.isReservationRequired ?? false
      : false,
    favoriteCharity:
      service?.favoriteCharity || defaultMerchant?.favoriteCharity,
    allowUsersToSelectADifferentCampaign:
      service?.allowUsersToSelectADifferentCampaign ??
      defaultMerchant?.allowUsersToSelectADifferentCampaign,
  }

  const [createProductVariation] = useMutation(
    CREATE_MERCHANT_OFFER_PRODUCT_VARIATION,
    {
      client: merchantClient,
    }
  )
  const [updateProductVariation] = useMutation(
    UPDATE_MERCHANT_OFFER_PRODUCT_VARIATION,
    {
      client: merchantClient,
    }
  )

  const handleLeaveModalClose = () => {
    setLeaveModalOpen(false)
  }
  const handleStopModalClose = () => {
    setStopModalOpen(false)
  }

  const handleConfirmDelete = () => {
    partialUpdateOffer({
      variables: {
        input: {
          id: service?.id,
          visibilityStatus: VISIBILITY_STATUS.ARCHIVED,
          endDate: new Date(),
        },
      },
    })
      .then(({ data, errors }) => {
        if (data?.deleteOffer?.success) {
          info(
            formatMessage({
              id: "dashboard::services::deleteMessage",
              defaultMessage: "Your service is deleted!",
            })
          )
        }
        error(errors?.message ?? errors?.[0]?.message)
      })
      .catch((err) => {
        error(
          err?.message ??
            formatMessage({
              id: "dashboard::stores::errorMessage",
              defaultMessage: "An error occurred",
            })
        )
      })
      .finally(() => {
        handleStopModalClose()
        isEventOffer
          ? navigate("/dashboard/merchants/events/event-offers/")
          : navigate("/dashboard/merchants/services/")
      })
  }

  const serviceMutationSuccessMessage = isUpdate
    ? formatMessage({
        id: "dashboard::serviceForm::updateMessage",
        defaultMessage: "Your updates are submitted for review.",
      })
    : formatMessage({
        id: "dashboard::serviceForm::created",
        defaultMessage: "Your service is created.",
      })

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true)
    setServiceBeingCreated(true)
    const {
      id: serviceId,
      productVariants,
      hasNoDeadline,
      hasNoGoal,
      isTermsAndPolicyAccepted,
      isUnlisted,
      categoryItems,
      isVirtualEvent,
      customAmountProductVariant,
      ...rest
    } = values

    const productVariantsToDelete = initialValues.productVariants.filter(
      (initialVariantValue) => {
        if (
          initialVariantValue.id &&
          !values.productVariants.find(
            (productVariant) => productVariant.id === initialVariantValue.id
          )
        )
          return initialVariantValue
      }
    )
    if (
      initialValues.customAmountProductVariant?.id &&
      initialValues.customAmountProductVariant?.id !==
        customAmountProductVariant?.id
    )
      productVariantsToDelete.push(initialValues.customAmountProductVariant)

    const isFutureService = differenceInDays(values.startDate, new Date()) > 0
    const newValues = {
      ...rest,
      maxPacDiscount: productVariants?.[0]?.pacDiscount?.toString(),
      endDate: values.endDate || null,
      visibilityStatus: values?.isUnlisted
        ? VISIBILITY_STATUS?.UNLISTED
        : VISIBILITY_STATUS?.LISTED,
      startDateVisibility: values?.isUnlisted
        ? VISIBILITY_STATUS?.UNLISTED
        : VISIBILITY_STATUS?.LISTED,
      categories: values?.categories?.length ? values.categories : [],
      isVoucher: values?.isVoucher,
      isEventOffer: values?.isEventOffer,
      isReservationRequired: values?.isVoucher
        ? values?.isReservationRequired
        : false,
      favoriteCharity:
        values?.favoriteCharity?.id ||
        values?.favoriteCharity?.value ||
        values?.favoriteCharity,
    }

    if (!values?.addressLine1) {
      newValues.addressLine1 = defaultMerchant?.addressLine1
      newValues.phoneNumber = defaultMerchant?.phoneNumber
    }
    const resultFieldName = isUpdate ? "offerSubmitForReview" : "createOffer"

    const resultHandler = ({ data: { [resultFieldName]: data } }) => {
      const { offer, errors } = { ...data }

      if (errors?.length) {
        errors?.forEach((err) => {
          if (err.field) {
            setFieldError(err.field, err.messages?.[0] || unknownError)
            const element = document?.querySelector(`[name="${err.field}"]`)
            if (element)
              element.scrollIntoView({ behavior: "smooth", block: "center" })
          } else {
            error(
              (err?.field
                ? err.field + " : " + err.messages?.[0]
                : err.messages?.[0]) ||
                err.message ||
                unknownError
            )
          }
        })
        errors?.forEach((err) => {
          if (err.message?.includes("Must be unique"))
            error(
              formatMessage({
                id: "field::error::nameUnique",
                defaultMessage: "Name should be unique",
              })
            )
          else error(err.messages?.[0] || err.message || unknownError)
        })
        setSubmitting(false)
        setServiceBeingCreated(false)
      } else {
        try {
          if (productVariantsToDelete.length) {
            Promise.all(
              productVariantsToDelete.map(
                ({
                  id,
                  name,
                  description,
                  price,
                  pacDiscount,
                  maxTickets,
                  isUnlimitedTickets,
                }) => {
                  updateProductVariation({
                    variables: {
                      input: {
                        id,
                        name,
                        description,
                        price,
                        pacDiscount: pacDiscount?.toString(),
                        maxTickets: isUnlimitedTickets
                          ? 100
                          : Number(maxTickets),

                        isUnlimitedTickets,
                        isActive: false,
                      },
                    },
                  })
                }
              )
            ).then((result) => {})
          }
          const allProductVariants = productVariants
          if (customAmountProductVariant) {
            customAmountProductVariant.allowCustomAmount = true
            customAmountProductVariant.price = "10"
            allProductVariants.push(customAmountProductVariant)
          }
          if (allProductVariants.length) {
            const hasOneVariant = allProductVariants.length == 1
            Promise.all(
              allProductVariants.map(({ id, ...productVariant }) => {
                const input = {
                  description: productVariant[`description${localeSuffix}`],
                  descriptionEn: productVariant.descriptionEn,
                  descriptionIt: productVariant.descriptionIt,
                  descriptionEs: productVariant.descriptionEs,
                  shortDescriptionEn: hasOneVariant
                    ? values.shortDescriptionEn
                    : productVariant?.shortDescriptionEn,
                  shortDescriptionIt: hasOneVariant
                    ? values.shortDescriptionIt
                    : productVariant?.shortDescriptionIt,
                  shortDescriptionEs: hasOneVariant
                    ? values.shortDescriptionEs
                    : productVariant?.shortDescriptionEs,
                  name: productVariant[`name${localeSuffix}`],
                  nameEn: productVariant.nameEn,
                  nameIt: productVariant.nameIt,
                  nameEs: productVariant.nameEs,
                  pacDiscount: String(productVariant?.pacDiscount || 0),
                  isUnlimitedTickets: productVariant?.isUnlimitedTickets,
                  price: String(productVariant.price || 0),
                  isActive: true,
                  allowCustomAmount: productVariant?.allowCustomAmount,
                  maxTickets: productVariant?.isUnlimitedTickets
                    ? 100
                    : Number(productVariant?.maxTickets),
                }
                if (id) {
                  updateProductVariation({
                    variables: { input: { ...input, id } },
                  })
                    .then(({ data }) => {
                      if (data?.updateDonationTier?.errors?.length) {
                        errorHandler(data.updateDonationTier.errors)
                      }
                    })
                    .catch((err) => {
                      errorHandler(err)
                    })
                } else {
                  createProductVariation({
                    variables: { input: { ...input, offer: offer?.id } },
                  })
                    .then(({ data }) => {
                      if (data?.createDonationTier?.errors?.length)
                        errorHandler(data?.createDonationTier?.errors)
                    })
                    .catch((err) => {
                      errorHandler(err)
                    })
                }
              })
            )
          }
        } catch (err) {
          errorHandler(err)
        } finally {
          setTimeout(() => {
            partialUpdateOffer({
              variables: {
                input: {
                  id: offer?.id,
                  reviewStatus: "READY",
                },
              },
            })
            success(serviceMutationSuccessMessage)
            isEventOffer
              ? navigate("/dashboard/merchants/events/event-offers/")
              : navigate("/dashboard/merchants/services/")
            setSubmitting(false)
            setServiceBeingCreated(false)
          }, 3000)
        }
      }
    }
    const errorHandler = (errors) => {
      if (!errors) return
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          error(
            (err?.field
              ? err.field + " : " + err.messages?.[0]
              : err.messages?.[0]) ||
              err.message ||
              unknownError
          )
        })
      } else error(errors?.message ?? unknownError)
      setSubmitting(false)
      setServiceBeingCreated(false)
    }
    if (isUpdate) {
      submitForReview({
        variables: {
          input: {
            ...newValues,
            id: service?.id,
            isVoucher: service?.isVoucher,
            isEventOffer: service?.isEventOffer,
          },
        },
        client: merchantClient,
      })
        .then((data) => resultHandler(data))
        .catch(errorHandler)
    } else {
      createOffer({
        variables: {
          input: newValues,
        },
        client: merchantClient,
      })
        .then((data) => {
          resultHandler(data)
        })
        .catch(errorHandler)
    }
  }

  const saveAddress = (values) => {
    if (!values?.id) return
    const resultFieldName = "offerSubmitForReview"
    const resultHandler = ({ data: { [resultFieldName]: data } }) => {
      const { offer, errors } = { ...data }
      if (!errors?.length) {
        setTimeout(() => {
          partialUpdateOffer({
            variables: {
              input: {
                id: offer?.id,
                reviewStatus: "READY",
              },
            },
          })
          success(
            formatMessage({
              id: "dashboard::serviceForm::updateMessage",
              defaultMessage: "Your updates are submitted for review.",
            })
          )
        }, 3000)
      }
    }
    const errorHandler = (errors) => {
      if (!errors) return
      if (Array.isArray(errors)) {
        errors.forEach((err) => {
          error(
            (err?.field
              ? err.field + " : " + err.messages?.[0]
              : err.messages?.[0]) ||
              err.message ||
              unknownError
          )
        })
      } else error(errors?.message ?? unknownError)
      setSubmitting(false)
      setServiceBeingCreated(false)
    }
    submitForReview({
      variables: {
        input: {
          addressLine1: values?.addressLine1 || defaultMerchant?.addressLine1,
          country: values?.country || defaultMerchant?.country,
          city: values?.city || defaultMerchant?.city,
          postCode: values?.postCode || defaultMerchant?.postCode,
          phoneNumber: values?.phoneNumber || defaultMerchant?.phoneNumber,
          id: service?.id,
        },
      },
      client: merchantClient,
    })
      .then((data) => resultHandler(data))
      .catch(errorHandler)
  }

  const handleStartDateChange = (date) => {
    const values = formRef?.current?.values
    const endDate =
      values.endDate && differenceInDays(values.endDate, date) < 0
        ? date
        : values.endDate
    formRef?.current?.setValues({
      ...values,
      startDate: date,
      endDate,
    })
  }

  const handleDeadlineDateChange = (date) => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
      endDate: date,
    })
  }

  const handleEnglishSectionChange = () => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
    })
    values?.productVariants?.forEach((item, index) => {
      formRef?.current?.setFieldValue(
        `productVariants[${index}].descriptionEn`,
        "",
        false
      )
    })
    setEnglishSectionOpen(!isEnglishSectionOpen)
  }

  const handleItalianSectionChange = () => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
    })
    values?.productVariants?.forEach((item, index) => {
      formRef?.current?.setFieldValue(
        `productVariants[${index}].descriptionIt`,
        "",
        false
      )
    })
    setItalianSectionOpen(!isItalianSectionOpen)
  }

  const handleSpanishSectionChange = () => {
    const values = formRef?.current?.values
    formRef?.current?.setValues({
      ...values,
    })
    values?.productVariants?.forEach((item, index) => {
      formRef?.current?.setFieldValue(
        `productVariants[${index}].descriptionEs`,
        "",
        false
      )
    })
    setSpanishSectionOpen(!isSpanishSectionOpen)
  }

  return loadingProfile ||
    offerDetailLoading ||
    (offerId && !service) ||
    isServiceBeingCreated ? (
    <Spinner condensed />
  ) : (
    <>
      <SEO
        lang={locale}
        title={
          isEventOffer
            ? newEventText
            : isVoucher
            ? newVoucherText
            : newServiceText
        }
      />
      <StyledWrapper>
        <StyledHeader>
          <div>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setLeaveModalOpen(true)}
            />
          </div>
          <StyledTitle data-testid="title">
            {isUpdate
              ? isEventOffer
                ? updateEventText
                : isVoucher
                ? updateVoucherText
                : updateServiceText
              : isEventOffer
              ? newEventText
              : isVoucher
              ? newVoucherText
              : newServiceText}
          </StyledTitle>
        </StyledHeader>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formRef}>
          {({ values, errors }) => {
            return (
              <>
                <OnSubmitValidationError />
                <ServiceCreationForm
                  {...{
                    service,
                    defaultMerchant,
                    isUpdate,
                    handleStartDateChange,
                    handleDeadlineDateChange,
                    allOfferCategories,
                    isEnglishSectionOpen,
                    handleEnglishSectionChange,
                    isItalianSectionOpen,
                    handleItalianSectionChange,
                    isSpanishSectionOpen,
                    handleSpanishSectionChange,
                    setStopModalOpen,
                    initialValues,
                    isVoucher,
                    isEventOffer,
                    setIsVoucher,
                    isServiceBeingCreated,
                    saveAddress,
                  }}
                />
                {isLeaveModalOpen && (
                  <LeaveModal
                    {...{
                      isLeaveModalOpen,
                      handleLeaveModalClose,
                      isMerchantOffline,
                      url: isEventOffer
                        ? "/dashboard/merchants/events/event-offers/"
                        : "/dashboard/merchants/services/",
                    }}
                  />
                )}

                {isStopModalOpen && (
                  <CustomModal
                    isModalOpen={isStopModalOpen}
                    style={isTablet && { textAlign: "unset" }}
                    children={
                      <>
                        <StyledSimpleTextContent className="delete-account-text"></StyledSimpleTextContent>
                        <Spacer bottom={isWide ? 3.5 : 1.5} />
                      </>
                    }
                    header={
                      <StyledUsedCardTitle>
                        <FormattedMessage
                          id="dashboard::services::confirmStop"
                          defaultMessage="Are you sure to stop?"
                        />
                      </StyledUsedCardTitle>
                    }
                    isCloseIcon={true}
                    cancelButtonText={
                      <FormattedMessage
                        id="dashboard::campaigns::modalNo"
                        defaultMessage="No"
                      />
                    }
                    cancelButtonAction={() => {
                      handleStopModalClose()
                    }}
                    confirmButtonText={
                      <FormattedMessage
                        id="dashboard::campaigns::modalYes"
                        defaultMessage="Yes"
                      />
                    }
                    confirmButtonAction={() => {
                      handleConfirmDelete()
                    }}
                    modalActionsStyle={{ display: "flex" }}
                    isMobile={!isTablet}
                    isFull={false}
                  />
                )}
              </>
            )
          }}
        </Formik>
      </StyledWrapper>
    </>
  )
}
export default ServiceForm
