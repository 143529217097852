import styled from "@emotion/styled"

export const StyledShareModalContainer = styled.div`
  display: flex;
  justify-content: ${(p) => p.position};
  align-items: ${(p) => p.position};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-bottom: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-top: 1rem;
  }
`

export const StyledSocialMethodIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.625rem;
  text-align: center;
  color: ${(p) => p.theme.colors.white};
`

export const StyledSocialMethod = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.currentColor};
  font-size: 1.75rem;
  svg {
    height: 1.75rem;
  }

  &.xtwitter {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 8, 1) 10%,
      rgba(255, 255, 255, 1) 120%
    );
  }
  &.whatsapp {
    background: #25d366;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 3rem;
    height: 3rem;
    border-radius: 48%;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;

    svg {
      height: 1.75rem;
    }
  }
`
export const StyledSocialMethodTitle = styled.span`
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.625rem;
    line-height: 1rem;

    margin-top: 0.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    line-height: 1.5rem;
    font-size: 1rem;

    margin-top: 1.25rem;
  }
`

export const StyledShareModalInputContainer = styled.div`
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 27.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    width: 44rem;
  }
`
